class PlaylistItemController {
  private playlist;
  private onFolderClick;
  private onPlaylistClick;
  private isSelected;

  isPlaylist() {
    return this.playlist.type === 'playlist';
  }

  isPrivate() {
    return (
      !this.playlist.capabilities ||
      (this.playlist.capabilities.isOwner && this.playlist.capabilities.policies.length <= 1)
    );
  }

  getCreatorName() {
    return this.playlist.createdBy.firstName + ' ' + this.playlist.createdBy.lastName;
  }

  getFriendlyCreatedAt() {
    return moment(this.playlist.createdAt).fromNow();
  }

  getClipsLength() {
    return (Object.keys(this.playlist.clipIndex) || []).length;
  }

  onClick() {
    if (this.isPlaylist()) {
      this.onPlaylistClick({ $playlist: this.playlist });
    } else {
      this.onFolderClick({ $playlist: this.playlist });
    }
  }
}

angular.module('app.scouting').component('playlistItem', {
  templateUrl: 'scouting/components/report/playlist-item.html',
  controller: PlaylistItemController as any,
  bindings: {
    isSelected: '<',
    playlist: '<',
    onFolderClick: '&',
    onPlaylistClick: '&',
  },
});
