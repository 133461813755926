import { Filter, FilterCriterion } from '../../services/ScoutingFilterService';

class ScoutingNewFilterButtonController {
  private $scoutingFilterService;

  private onFilterSelected: ({ $filter }: { $filter: Filter }) => void;

  constructor(
    private $filter,
    ScoutingFilterService,
  ) {
    this.$scoutingFilterService = ScoutingFilterService;
  }

  get availableCriteria() {
    return this.$scoutingFilterService.availableCriteria;
  }

  select(criterion: FilterCriterion) {
    this.onFilterSelected({
      $filter: {
        criterion: criterion.name,
        field: criterion.field,
        negative: false,
        value: {},
      },
    });
  }

  criterionNameTransfomer() {
    const filter = this.$filter('scTranslate');

    return (citerion: FilterCriterion) => filter(`scouting.filters.${citerion.name}`);
  }
}

angular.module('app.scouting').component('scoutingNewFilterButton', {
  templateUrl: 'scouting/components/filters/new-filter-button.html',
  controller: ScoutingNewFilterButtonController,
  bindings: {
    onFilterSelected: '&',
  },
});
