import { TeamRecord } from '../../../team/services/TeamService';
import { Lineup, LineupItem } from '../../services/ScoutingService';

const ABSENSE_REASONS = [
  { name: 'scratch', display: 'Healthy scratch' },
  { name: 'miss', display: 'Not in line up' },
  { name: 'ill', display: 'Not in line up - ill' },
  { name: 'injured', display: 'Not in line up - injured' },
  { name: 'suspended', display: 'Not in line up - suspended' },
  { name: 'call_up', display: 'Call Up' },
  { name: 'sent_down', display: 'Sent Down' },
];

class ScoutingTeamLineupController {
  private team: TeamRecord;
  private lineup: Lineup;
  private gameReportLineup: Lineup;
  private currentLineup: any = {
    goalies: {},
    lines: [],
    playerIds: Set,
  };
  private sortedPower;
  private sortedBox;
  private sortedEven;

  get absenseReasons() {
    return ABSENSE_REASONS;
  }

  $onInit() {
    if (!this.lineup.power) {
      this.lineup.power = [
        { scheme: { we: null, they: null } },
        { scheme: { we: null, they: null } },
      ];
    }

    if (!this.lineup.box) {
      this.lineup.box = [
        { scheme: { we: null, they: null } },
        { scheme: { we: null, they: null } },
      ];
    }

    if (!this.lineup.even) {
      this.lineup.even = [];
    }
    this.sortLineupItems();
  }

  $onChanges(changes) {
    if (changes.lineup || changes.gameReportLineup) {
      this.updateCurrentLineup();
      this.sortLineupItems();
    }
  }

  updateCurrentLineup() {
    const currentLineup: any = {
      goalies: {},
      lines: [],
      playerIds: Set,
    };
    const playerInsideLineupIds = new Set();

    currentLineup.goalies = {};
    // goalies
    currentLineup.goalies.GK1 = _.get(this.lineup, ['goalies', 'GK1']);
    if (currentLineup.goalies.GK1 === undefined) {
      currentLineup.goalies.GK1 = _.get(this.gameReportLineup, ['goalies', 'GK1']);
    }
    if (currentLineup.goalies.GK1) {
      playerInsideLineupIds.add(currentLineup.goalies.GK1);
    }

    currentLineup.goalies.GK2 = _.get(this.lineup, ['goalies', 'GK2']);
    if (currentLineup.goalies.GK2 === undefined) {
      currentLineup.goalies.GK2 = _.get(this.gameReportLineup, ['goalies', 'GK2']);
    }
    if (currentLineup.goalies.GK2) {
      playerInsideLineupIds.add(currentLineup.goalies.GK2);
    }
    currentLineup.goalies.GK3 = _.get(this.lineup, ['goalies', 'GK3']);
    if (currentLineup.goalies.GK3 === undefined) {
      currentLineup.goalies.GK3 = _.get(this.gameReportLineup, ['goalies', 'GK3']);
    }
    if (currentLineup.goalies.GK3) {
      playerInsideLineupIds.add(currentLineup.goalies.GK3);
    }

    // skater
    currentLineup.lines = [];
    ['1', '2', '3', '4', '5'].forEach((line) => {
      currentLineup.lines[line] = {};
      ['RD', 'LD', 'RW', 'LW', 'C'].forEach((position) => {
        currentLineup.lines[line][position] = _.get(this.lineup, ['lines', line, position]);
        if (currentLineup.lines[line][position] === undefined) {
          currentLineup.lines[line][position] = _.get(this.gameReportLineup, [
            'lines',
            line,
            position,
          ]);
        }
        if (currentLineup.lines[line][position]) {
          playerInsideLineupIds.add(currentLineup.lines[line][position]);
        }
      });
    });
    currentLineup.playerIds = playerInsideLineupIds;
    this.currentLineup = currentLineup;
  }

  isPositionMatches(position) {
    return (wrapper) => {
      switch (wrapper.player.playerPosition) {
        case 'CENTRE':
        case 'RIGHT_WING':
        case 'LEFT_WING':
        case 'FORWARD':
          return position === 'FORWARD';

        case 'DEFENSEMAN':
        case 'LEFT_DEFENSEMAN':
        case 'RIGHT_DEFENSEMAN':
          return position === 'DEFENSEMAN';

        default:
          return position === wrapper.player.playerPosition;
      }
    };
  }

  isSuitableButNotPlayed() {
    return (player) => {
      return (
        player.team === (this.team._id || this.team.temporaryId) &&
        !this.currentLineup.playerIds.has(player.player._id)
      );
    };
  }

  getMissedHolder(player) {
    if (!this.lineup.missing) {
      this.lineup.missing = [];
    }

    return new MissingPlayerHolder(this.lineup, player._id);
  }

  getMissedDisplay(player) {
    const reason = this.getMissedHolder(player).reason;
    const type = ABSENSE_REASONS.find((item) => item.name == reason);

    return type ? type.display : null;
  }

  removeLine(list, item) {
    if (list.includes(item)) {
      list.splice(list.indexOf(item), 1);
    }
    this.sortLineupItems();
  }

  set newStrengthLine(details) {
    this.lineup[details.type].push({
      scheme: { we: details.we, they: details.they },
    });
    this.sortLineupItems();
  }

  sortLineupItems() {
    const sort = (lineupItem: LineupItem[]) =>
      _.chain(lineupItem)
        .groupBy((item) => item.scheme.we + '-' + item.scheme.they)
        .toPairs()
        .sort((a, b) => {
          const aSchema = a[0];
          const bSchema = b[0];
          return aSchema < bSchema ? 1 : aSchema === bSchema ? 0 : -1;
        })
        .value();
    this.sortedPower = sort(this.lineup.power);
    this.sortedBox = sort(this.lineup.box);
    this.sortedEven = sort(this.lineup.even);
  }
}

class MissingPlayerHolder {
  private holder;

  constructor(
    private lineup: Lineup,
    private playerId: string,
  ) {
    this.holder = this.lineup.missing.find((item) => item.player == playerId);
  }

  get reason() {
    return this.holder ? this.holder.reason : null;
  }

  set reason(value: string) {
    if (!this.holder) {
      this.holder = {
        player: this.playerId,
        reason: null,
      };

      this.lineup.missing.push(this.holder);
    }

    this.holder.reason = value;
  }
}

angular.module('app.scouting').component('scoutingTeamLineup', {
  templateUrl: 'scouting/components/general/lineup.html',
  controller: ScoutingTeamLineupController,
  bindings: {
    team: '<',
    players: '<',
    gameReportLineup: '<',
    lineup: '<',
    readonly: '<',
  },
});
