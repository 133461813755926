import { TeamRecord } from '../../../team/services/TeamService';
import { RosterItem } from '../../services/PendingReportsService';
import { Lineup } from '../../services/ScoutingService';

class ScoutingLineupItemController {
  private $searchService;

  private line: number;
  private bucket: string;
  private position: string;
  private exclusive: boolean;
  private direct: boolean;

  private players: RosterItem[];
  private playerIdsInLineup: Set<string>;
  private holder: Lineup;
  private team: TeamRecord;

  private selectedWrapper: RosterItem;
  private onUpdate;

  constructor(
    SearchService,
    private PLAYER_IMPORTANT_PROPERTIES,
  ) {
    this.$searchService = SearchService;
  }

  $onInit() {
    this.ensureLineExists();
  }

  $onChanges(changes) {
    if (changes.playerId) {
      this.selectedWrapper = this.players.find(
        (item) => item.player._id === _.get(changes, ['playerId', 'currentValue']),
      );
    }
  }

  async findAvailablePlayer(query) {
    const probableJerseyNumber = (query.match(/\d+/) || [])[0];
    const textQuery = _.deburr((query.match(/\D/g) || []).join(''))
      .replace(/\s+/g, ' ')
      .trim()
      .toLocaleLowerCase();

    let players = this.players.filter(
      (item) => item.team === (this.team._id || this.team.temporaryId),
    );

    if (this.exclusive) {
      players = players.filter(
        (item) =>
          (this.selectedWrapper && item.player._id === this.selectedWrapper.player._id) ||
          !this.playerIdsInLineup.has(item.player._id),
      );
    }

    if (this.bucket === 'goalies') {
      players = players.filter((item) => item.player.playerPosition === 'GOALIE');
    } else {
      players = players.filter((item) => item.player.playerPosition !== 'GOALIE');
    }

    players = players.filter((item) => {
      let jerseySearchPassed = true;

      if (probableJerseyNumber) {
        jerseySearchPassed = (item.jerseyNumber + '').includes(probableJerseyNumber);
      }

      if (textQuery && jerseySearchPassed) {
        return (
          _.deburr(`${item.player.firstName} ${item.player.lastName}`)
            .toLocaleLowerCase()
            .includes(textQuery) ||
          _.deburr(`${item.player.lastName} ${item.player.firstName}`)
            .toLocaleLowerCase()
            .includes(textQuery)
        );
      }
      return jerseySearchPassed;
    });

    if (!players.length && query.length > 0) {
      players = (
        await this.$searchService.searchForPlayers(query, {
          goalie: this.position.includes('GK'),
        })
      ).players.map((item) => {
        return {
          player: _.pick(item._source, this.PLAYER_IMPORTANT_PROPERTIES),
          selected: false,
          custom: true,
        };
      });
    }

    return players;
  }

  get selectedPlayerJerseyNumber() {
    return this.selectedWrapper ? this.selectedWrapper.jerseyNumber : null;
  }
  set selectedPlayerJerseyNumber(value: number) {
    this.selectedWrapper.jerseyNumber = value;
  }

  get replacement() {
    return this.holder.goalies.replacements;
  }
  set replacement(value) {
    this.holder.goalies.replacements = value;
  }

  setSelectedPlayer(wrapper: RosterItem) {
    if (wrapper) {
      if (this.selectedWrapper) {
        this.selectedWrapper.lineup = false;
      }
      if (!this.players.includes(wrapper)) {
        const existingWrapper = this.players.find((item) => item.player._id === wrapper.player._id);

        if (existingWrapper) {
          wrapper = existingWrapper;
        } else {
          wrapper.team = this.team._id || this.team.temporaryId;
          this.players.push(wrapper);
        }
      }

      this.selectedWrapper = wrapper;
      if (!this.direct) {
        this.selectedWrapper.lineup = true;
      }

      _.set(this.holder, this.playerProperyPath, wrapper.player._id);

      // remove from missing players
      this.holder.missing = this.holder.missing.filter(
        (item) => item.player !== wrapper.player._id,
      );
    } else {
      if (this.selectedWrapper) {
        this.selectedWrapper.lineup = false;
      }
      _.set(this.holder, this.playerProperyPath, null);
    }
    this.onUpdate();
  }

  private ensureLineExists() {
    if (this.direct) {
      return;
    }

    if (this.line == null) {
      if (!this.holder[this.bucket]) {
        this.holder[this.bucket] = {};
      }

      return;
    }

    if (!this.holder[this.bucket]) {
      this.holder[this.bucket] = [];
    }

    if (!this.holder[this.bucket][this.line]) {
      this.holder[this.bucket][this.line] = {};
    }
  }

  private get playerProperyPath() {
    if (this.direct) {
      return this.position;
    }
    return this.line == null
      ? `${this.bucket}.${this.position}`
      : `${this.bucket}.${this.line}.${this.position}`;
  }
}

angular.module('app.scouting').component('lineupItem', {
  templateUrl: 'scouting/components/general/lineup-item.html',
  controller: ScoutingLineupItemController as any,
  bindings: {
    line: '<',
    side: '@',
    bucket: '@',
    direct: '@',
    position: '@',
    exclusive: '@',

    team: '<',
    holder: '<',
    players: '<',
    playerIdsInLineup: '<',
    playerId: '<',
    readonly: '<',
    onUpdate: '&',
  },
});
