class ScoutingReportOverviewTagsController {
  private $resource;

  private report;
  private playerId: string;

  constructor(ScoutingReportResource) {
    this.$resource = ScoutingReportResource;
  }

  getPlayerTags(playerId) {
    const players: any = _.get(this, ['report', 'players']);
    if (players && players.tags) {
      return players.tags;
    }

    if (players instanceof Array && playerId) {
      return players.find(
        (wrapper) => wrapper.player._id == playerId || wrapper.player.temporaryId == playerId,
      ).tags;
    }
  }

  removeReportTag(tag) {
    const tags = this.report.tags;

    tags.splice(tags.indexOf(tag), 1);
    this.$resource.removeReportTag({ tag }, { _id: this.report._id });
  }

  removePlayerTag(tag) {
    const tags = this.getPlayerTags(this.playerId);

    tags.splice(tags.indexOf(tag), 1);
    this.$resource.removePlayerTag({ player: this.playerId, tag }, { _id: this.report._id });
  }
}

angular.module('app.scouting').component('scoutingReportOverviewTags', {
  templateUrl: 'scouting/components/report-overview-tags.html',
  controller: ScoutingReportOverviewTagsController,
  bindings: {
    report: '<',
    playerId: '<',
  },
});
