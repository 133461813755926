import { Lineup, RosterItem } from '../../services/ScoutingService';

class ScoutingPlayerLineupOverviewController {
  private player: RosterItem;
  private report: any;
  private gameReportLineups: any;

  private teamKind: string;
  private lines: { name: string; position: string; scheme?: string }[];

  constructor(private $scope) {}

  $onChanges(changes) {
    const report = this.report || _.get(changes.report, 'currentValue');
    const player = _.get(changes.player, 'currentValue') as RosterItem;

    if (player) {
      this.teamKind = null;

      if (report.game && report.game.home._id === player.team) {
        this.teamKind = 'home';
      } else if (report.game && report.game.away._id === player.team) {
        this.teamKind = 'away';
      }
    }
    this.update();
  }

  get lineup(): Lineup {
    if (!this.report || !this.report.game || !this.teamKind) {
      return null;
    }

    return this.teamKind ? this.report.game[`${this.teamKind}_lineup`] : null;
  }

  update() {
    this.lines = [];

    if (this.lineup) {
      ['power', 'box', 'even'].forEach((type) => {
        const availableLines = (this.lineup[type] || []).map((item) => {
          return {
            name: type,
            scheme:
              item.scheme.they && item.scheme.we ? `${item.scheme.we}-${item.scheme.they}` : null,
            position: this.findLineupPosition(item, this.player.player._id),
          };
        });

        this.lines = this.lines.concat(availableLines.filter((item) => !!item.position));
      });
      ['lines'].forEach((type) => {
        const availableLines = (this.lineup[type] || []).map((item, index) => {
          return {
            name: 'line',
            scheme: index,
            position: this.findLineupPosition(
              item,
              this.player.player._id,
              this.gameReportLineups && this.gameReportLineups[this.teamKind][type]
                ? this.gameReportLineups[this.teamKind][type][index]
                : undefined,
            ),
          };
        });

        this.lines = this.lines.concat(availableLines.filter((item) => !!item.position));
      });
      ['goalies'].forEach((type) => {
        const availableLines = [
          {
            name: type,
            position: this.findLineupPosition(this.lineup[type], this.player.player._id),
          },
        ];

        this.lines = this.lines.concat(availableLines.filter((item) => !!item.position));
      });

      this.lines = _.uniqBy(this.lines, 'name');
      this.lines = _.sortBy(this.lines, (item) => {
        switch (item.name) {
          case 'goalies':
            return 1;
          case 'line':
            return 2;
          case 'power':
            return 3;
          case 'box':
            return 4;
          case 'even':
            return 5;
          default:
            return 0;
        }
      });
    }
  }

  findLineupPosition(lineup, playerId, gameReportLineup?: any) {
    const lineupValue =
      _.chain(lineup)
        .pickBy((item) => item === playerId)
        .keys()
        .first()
        .value() || undefined;

    if (lineupValue !== undefined) {
      return lineupValue;
    }
    const lineValueInGameReport =
      _.chain(gameReportLineup)
        .pickBy((item) => item === playerId)
        .keys()
        .first()
        .value() || undefined;

    if (lineValueInGameReport && lineup[lineValueInGameReport] !== null) {
      return lineValueInGameReport;
    }
    return null;
  }
}

angular.module('app.scouting').component('scoutingPlayerLineupOverview', {
  templateUrl: 'scouting/components/general/player-lineup-overview.html',
  controller: ScoutingPlayerLineupOverviewController,
  bindings: {
    player: '<',
    report: '<',
    gameReportLineups: '<',
  },
});
