class PlayerCollectionsService {
  private $http;
  private getPlayerCollectionsEndpoint;

  constructor(SCConfiguration, $http) {
    this.$http = $http;
    this.getPlayerCollectionsEndpoint = SCConfiguration.getEndpoint() + '/api/player-collection';
  }

  fetchPlayerCollections() {
    return this.$http.get(this.getPlayerCollectionsEndpoint).then((response) => response.data);
  }

  fetchPlayerValues(collectionId, listId, { skip, limit }) {
    const endPoint = `${this.getPlayerCollectionsEndpoint}/${collectionId}/list/${listId}/playerValues?skip=${skip}&limit=${limit}`;
    return this.$http.get(endPoint).then((response) => response.data);
  }
}

angular.module('app.scouting').service('PlayerCollectionsService', PlayerCollectionsService);
