/**
 * TODO
 *
 * - [x] load only Note/Memo template
 * - [x] load all reports based on the present players & columns
 * - [x] Delete column
 * - [x] Edit comment
 * - [x] Remove comment
 * - [x] Permissions (comments-board-access, comments-board-invite)
 * - [x] Invite people to comments board
 * - [x] Update reports query so that the people has access to Comments Board should be able to see
 *      the reports in the list view and players view
 * - [] Create Note/Memo in normal way (provide option to make it visible in comment board?)
 * - [x] Display labels for player
 */

interface PlayerData {
  player: any;
  labels: any[];
}

class CommentsBoardController {
  private modalInstance;
  private players: PlayerData[];

  private scoutingSheet;
  private allComments: any[];
  private commentsByPlayer: any;
  private membersHasAccess: any[];
  private fixedMembers: any[];

  private state;
  private user;
  private $promise;

  public constructor(
    private OrganizationService,
    private ScoutingService,
    private ScoutingReportResource,
    private UserService,
    private $uibModal,
    private $filter,
    private $timeout,
    private Toastr,
    private _,
  ) {
    this.state = {};
  }

  public $onInit() {
    this.user = this.UserService.getUser();
    this.$promise = this.fetchData();
  }

  public async fetchData() {
    const members = await this.OrganizationService.listMembers();
    this.membersHasAccess = members
      .filter((member: any) => member.rights.indexOf('commentsboardaccess') > -1)
      .map((user: any) => {
        const firstName = this._.get(user, 'profile.firstName', '');
        const lastName = this._.get(user, 'profile.lastName', '');
        const position = this._.get(user, 'profile.playerPosition', '');
        const fullName = this._.joinIfPresent(' ', firstName, lastName);

        return {
          _id: this._.get(user, '_id', ''),
          type: 'user',
          organizationId: this.user.account._id,
          roles: (user.roles || []).join(', '),
          display: fullName,
          position,
        };
      });
    this.fixedMembers = [this.membersHasAccess.find((member) => member._id === this.user._id)];
    this.scoutingSheet = await this.ScoutingService.getScoutingSheet();
    this.allComments = await this.ScoutingService.getScoutingSheetComments(
      this.scoutingSheet._id,
      this.players.map((player) => player.player._id),
      this.scoutingSheet.templates.map((tpl) => tpl._id),
    );

    this.groupComments();
  }

  public groupComments() {
    const commentsByPlayer: any = {};

    this.players.forEach((player) => {
      let playerComments = this.allComments.filter((comment) =>
        comment.players.some((playerWrapper) => playerWrapper.player._id === player.player._id),
      );
      playerComments = playerComments
        .map((comment) => {
          const playerWrapper = comment.players.find(
            (playerWrapper) => playerWrapper.player._id === player.player._id,
          );
          const commentValueWrapper = (playerWrapper.values || []).find(
            (value) => value.type === 'text_field',
          );
          return {
            ...comment,
            $$commentWrapper: {
              player: playerWrapper.player,
              commentText: commentValueWrapper ? commentValueWrapper.value : '',
            },
          };
        })
        // filter only the c
        .filter((comment) => Boolean(comment.$$commentWrapper.commentText));

      commentsByPlayer[player.player._id] = _.groupBy(playerComments, 'template._id');
    });

    this.commentsByPlayer = commentsByPlayer;
  }

  public addColumn() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const modal = this.$uibModal.open({
      template:
        '<comments-board-add-column modal-instance="$ctrl.modalInstance" scouting-sheet="$ctrl.scoutingSheet" />',
      controllerAs: '$ctrl',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.scoutingSheet = self.scoutingSheet;
        },
      ],
    });

    modal.result.then((updatedScoutingSheet) => {
      this.scoutingSheet = updatedScoutingSheet;
      this.$onInit();
    }, angular.noop);
  }

  public async removeColumn(template) {
    this.$promise = this.ScoutingService.removeColumnFromScoutingSheet(
      this.scoutingSheet._id,
      template,
    );
    await this.$promise;
    this.$onInit();
  }

  public addCommentFor($event, player: PlayerData, template) {
    const $button = $($event.currentTarget);
    const $wrapper = $button.closest('.player-comments-cell');

    _.set(this.state, `${player.player._id}.${template._id}`, {
      isAdding: true,
      commentText: '',
    });

    this.$timeout(() => {
      $wrapper.find('textarea')[0].focus();
    }, 100);
  }

  public cancelAddComment(player: PlayerData, template) {
    _.set(this.state, `${player.player._id}.${template._id}`, {
      isAdding: false,
      commentText: '',
    });
  }

  public async sendComment(player: PlayerData, template) {
    const commentText = _.get(this.state, `${player.player._id}.${template._id}.commentText`, '');

    if (commentText) {
      _.set(this.state, `${player.player._id}.${template._id}.isSending`, true);

      const payload = {
        template: {
          _id: template._id,
          name: template.name,
        },
        player: {
          team: player.player.latestPlayerStats.team,
          jerseyNumber: player.player.latestPlayerStats.jerseyNumber,
          player: _.pick(player.player, [
            '_id',
            'firstName',
            'lastName',
            'imageUrl',
            'playerPosition',
            'dateOfBirth',
            'height',
            'weight',
            'shoots',
            'contract',
            'country',
          ]),
        },
        comment: commentText,
      };

      const newComment = await this.ScoutingService.createScoutingSheetComment(
        this.scoutingSheet._id,
        payload,
      );

      this.allComments = [newComment, ...this.allComments];

      this.groupComments();
    }

    _.set(this.state, `${player.player._id}.${template._id}`, {
      isSending: false,
      isAdding: false,
      commentText: '',
    });
  }

  public async updateComment(comment) {
    const currentComment = _.get(comment, '$$commentWrapper.commentText', '');
    const newComment = (comment.$$newComment || '').trim();

    if (newComment && newComment !== currentComment) {
      const playerWrapper = comment.players.find(
        (pw) => pw.player._id === comment.$$commentWrapper.player._id,
      );
      const commentValueWrapper = (playerWrapper.values || []).find(
        (value) => value.type === 'text_field',
      );

      if (commentValueWrapper) {
        _.set(commentValueWrapper, 'value', newComment);
      }

      // delete all the convinience fields in the frontend
      delete comment.$$commentWrapper;
      delete comment.$$isEditing;
      delete comment.$$newComment;

      await this.ScoutingService.triggerChangedReportSave(comment);

      comment.$$commentWrapper = {
        player: playerWrapper.player,
        commentText: commentValueWrapper ? commentValueWrapper.value : '',
      };
    }

    comment.$$isEditing = false;
  }

  public showDeleteConfirmation(comment) {
    const modal = this._showConfirmationModal('scouting.Comments_board_delete_comment_confirm');
    modal.result.then(() => {
      this.deleteComment(comment);
    }, angular.noop);
  }

  // TODO
  // when note/memo reports has multiple players in it
  // should delete one comment will  delete the whole report???
  public async deleteComment(comment) {
    const idx = this.allComments.findIndex((c) => c._id === comment._id);
    await new this.ScoutingReportResource(comment).$delete();

    this.allComments = this.allComments.filter((item, i) => i !== idx);
    this.groupComments();
  }

  public grantAccess(members) {
    const additions = this._.differenceBy(members, this.membersHasAccess, '_id');
    const removals = this._.differenceBy(this.membersHasAccess, members, '_id');

    if (additions.length) {
      const translatedMessage = this.$filter('scTranslate')(
        'scouting.Comments_board_grant_access_confirm',
        {
          userName: additions[0].display,
        },
      );
      const modal = this._showConfirmationModal('', translatedMessage);
      modal.result.then(() => {
        this.$promise = this.ScoutingService.grantOrRevokeScoutingSheetAccess(
          this.scoutingSheet._id,
          {
            add: additions,
          },
        )
          .then(() => {
            this.membersHasAccess = members;
          })
          .catch((_err) => {
            this.Toastr.error('Something wrong!');
          });
      }, angular.noop);
    } else if (removals.length) {
      const translatedMessage = this.$filter('scTranslate')(
        'scouting.Comments_board_remove_access_confirm',
        {
          userName: removals[0].display,
        },
      );
      const modal = this._showConfirmationModal('', translatedMessage);
      modal.result.then(() => {
        this.$promise = this.ScoutingService.grantOrRevokeScoutingSheetAccess(
          this.scoutingSheet._id,
          {
            remove: removals,
          },
        )
          .then(() => {
            this.membersHasAccess = members;
          })
          .catch((_err) => {
            this.Toastr.error('Something wrong!');
          });
      }, angular.noop);
    }
  }

  public close() {
    this.modalInstance.dismiss();
  }

  private _showConfirmationModal(message, translatedMessage?) {
    return this.$uibModal.open({
      template:
        '<confirmation-modal modal-instance="$ctrl.modalInstance" message="$ctrl.message" translated-message="$ctrl.translatedMessage"/>',
      controllerAs: '$ctrl',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.message = message;
          this.translatedMessage = translatedMessage;
        },
      ],
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public hasNamePredicate(selectedLabel) {
    return (selectedLabel) => !!selectedLabel.label;
  }
}

angular.module('app.scouting').component('commentsBoard', {
  templateUrl: 'scouting/components/modals/comments-board.html',
  controller: CommentsBoardController,
  bindings: {
    players: '<',
    modalInstance: '<',
  },
});
