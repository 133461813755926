(function () {
  'use strict';

  class ScoutingReportDropdownFieldController {
    private params;
    private value;

    constructor(private $scope) {}

    getOptions(player) {
      switch (player.player.playerPosition) {
        case 'GOALIE':
          return this.params.goaliesOptions || this.params.options;

        default:
          return this.params.options;
      }
    }

    findOption(player, value) {
      return this.getOptions(player).find((option) => option.value == value);
    }

    notifyAboutChanges() {
      this.$scope.$emit('scouting:element:changed');
    }
  }

  angular.module('app.scouting').component('scoutingReportFieldDropdown', {
    templateUrl: 'scouting/components/field/dropdown.html',
    controller: ScoutingReportDropdownFieldController,
    bindings: { params: '<', value: '=', editable: '<', selectedPlayer: '<' },
  });
})();
