import { PlayerService } from '../../../player/services/PlayerService';
import { TeamRecord } from '../../../team/services/TeamService';
import { RosterItem } from '../../services/ScoutingService';

class ScoutingRosterController {
  private $teamService;
  private $playerService: PlayerService;
  private $searchService;
  private $scoutingService;

  private promise;
  private season: number;
  private players: RosterItem[];
  private teams: TeamRecord[];
  private autoselectedPlayerIds: string[];
  private forceAddPlayersTo: TeamRecord;

  private compact: boolean;
  private brief: boolean;

  constructor(
    private $scope,
    private $q,
    TeamService,
    PlayerService,
    SearchService,
    ScoutingService,
    private PLAYER_IMPORTANT_PROPERTIES,
  ) {
    this.$teamService = TeamService;
    this.$playerService = PlayerService;
    this.$searchService = SearchService;
    this.$scoutingService = ScoutingService;
  }

  $onInit() {
    this.players = this.players || [];

    this.$scope.$watchGroup([() => this.teams, () => this.season], (current, previous) =>
      this.update(current != previous),
    );
  }

  update(force: boolean) {
    if (force) {
      _.remove(this.players);
    } else {
      _.remove(
        this.players,
        (item) => !this.teams.some((team) => item.team == (team._id || team.temporaryId)),
      );
    }

    this.promise = this.$q.resolve().then(async () => {
      const missingRosterTeams = this.teams.filter(
        (team) => !this.players.some((item) => item.team == (team._id || team.temporaryId)),
      );

      for (const team of missingRosterTeams) {
        await this.populateTeamRoster(team);
      }

      this.brief = false;
      this.players.forEach((wrapper) => {
        if (this.autoselectedPlayerIds && this.autoselectedPlayerIds.includes(wrapper.player._id)) {
          wrapper.selected = true;

          this.brief = this.compact;
        }
      });
    });
  }

  isPositionMatches(position) {
    return (wrapper) => {
      switch (wrapper.player.playerPosition) {
        case 'CENTRE':
        case 'RIGHT_WING':
        case 'LEFT_WING':
        case 'FORWARD':
          return position == 'FORWARD';

        case 'DEFENSEMAN':
        case 'LEFT_DEFENSEMAN':
        case 'RIGHT_DEFENSEMAN':
          return position == 'DEFENSEMAN';

        case null:
        case undefined:
        case 'PLAYER':
          return position == 'PLAYER';

        default:
          return position == wrapper.player.playerPosition;
      }
    };
  }

  async findPlayer(query, isGoalie) {
    const result = await this.$searchService.searchForPlayers(query, {
      goalie: isGoalie,
      season: this.season,
    });

    return result.players.map((item) => {
      return {
        player: _.pick(item._source, this.PLAYER_IMPORTANT_PROPERTIES),
        selected: false,
        custom: true,
      };
    });
  }

  createSelectionObserver(position, team) {
    return new SelectionObserver((wrapper) => {
      team.$$addPlayerToRosterState[position] = false;

      if (wrapper) {
        if (!wrapper.player.playerPosition) {
          wrapper.player = Object.assign(wrapper.player, { playerPosition: position });
        }

        _.remove(this.players, (inner) => inner.player._id === wrapper.player._id);
        this.players.push(Object.assign({ team: team._id || team.temporaryId }, wrapper));
      }
    });
  }

  hasUnclassifiedPlayers(players: any[]) {
    return players && !!players.find((item) => !item.player.playerPosition);
  }

  private async populateTeamRoster(team: TeamRecord) {
    this.$scope.$emit('scouting:roster:populateTeamRoster', { populatingTeamRoster: true });
    if (team && team._id) {
      const roster = await this.$teamService.getGroupedRoster(team._id, this.season);

      [
        ...(roster.GOALIE || []),
        ...(roster.DEFENSEMAN || []),
        ...(roster.FORWARD || []),
        ...(roster.PLAYER || []),
      ].forEach((wrapper) => {
        this.players.push({
          team: team._id,
          jerseyNumber: wrapper.jerseyNumber,
          player: _.pick(wrapper.player, this.PLAYER_IMPORTANT_PROPERTIES),
          selected: false,
        });
      });
    } else if (team && team.temporaryId) {
      const roster = await this.$scoutingService.getTemporaryRoster(team.temporaryId);

      this.players.splice(0, 0, ...roster);
    }

    if (team && this.autoselectedPlayerIds && this.forceAddPlayersTo === team) {
      for (const playerId of this.autoselectedPlayerIds) {
        if (!this.players.some((wrapper) => wrapper.player._id === playerId)) {
          const player = await this.$playerService.getPlayer(playerId);

          this.players.push({
            team: team._id,
            jerseyNumber: null,
            player: _.pick(player, this.PLAYER_IMPORTANT_PROPERTIES),
            selected: false,
          });
        }
      }
    }
    this.$scope.$emit('scouting:roster:populateTeamRoster', { populatingTeamRoster: false });
  }

  private remove(wrapper: RosterItem) {
    if (this.players.includes(wrapper)) {
      this.players.splice(this.players.indexOf(wrapper), 1);
    }
  }
}

class SelectionObserver {
  private isInitialized = false;

  constructor(private callback) {}

  set player(value) {
    if (!value && !this.isInitialized) {
      this.isInitialized = true;
      return;
    }

    this.callback(value);
  }
}

angular.module('app.scouting').component('scoutingRoster', {
  templateUrl: 'scouting/components/general/roster.html',
  controller: ScoutingRosterController,
  bindings: {
    teams: '<',
    season: '<',
    readonly: '<',
    autoselectedPlayerIds: '<',
    forceAddPlayersTo: '<',
    players: '=',
    compact: '@',
  },
});
