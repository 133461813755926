var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var DepartmenAddPlayerModalController = (function () {
    function DepartmenAddPlayerModalController($q, ScoutingLabelsResource) {
        this.$q = $q;
        this.$labelsResource = ScoutingLabelsResource;
        this.newUnnamedLabel = {};
    }
    DepartmenAddPlayerModalController.prototype.$onInit = function () {
        var _this = this;
        this.selected = [];
        this.labels = this.$labelsResource.query();
        this.promise = this.labels.$promise.then(function (labels) {
            var unnamedLabel = labels.find(function (label) { return !label.label; });
            if (!unnamedLabel) {
                _this.labels = __spreadArray([_this.newUnnamedLabel], _this.labels, true);
            }
            else {
                _this.labels = __spreadArray([unnamedLabel], _this.labels.filter(function (label) { return !!label.label; }), true);
            }
            _this.selected =
                _this.player.labels && _this.player.labels.length
                    ? labels.filter(function (item) { return _this.player.labels.some(function (label) { return label._id === item._id; }); })
                    : [unnamedLabel ? unnamedLabel : _this.newUnnamedLabel];
        });
    };
    DepartmenAddPlayerModalController.prototype.cancel = function () {
        this.$uibModal.dismiss();
    };
    DepartmenAddPlayerModalController.prototype.confirm = function () {
        var _this = this;
        this.promise = this.save().then(function () { return _this.$uibModal.close(); });
    };
    DepartmenAddPlayerModalController.prototype.hasNamePredicate = function () {
        return function (label) { return !!label.label; };
    };
    DepartmenAddPlayerModalController.prototype.isSelected = function (label) {
        return (this.selected || []).some(function (selection) { return selection === label; });
    };
    DepartmenAddPlayerModalController.prototype.toggleLabel = function ($event, label) {
        if (this.isSelected(label)) {
            this.selected = this.selected.filter(function (item) { return item !== label; });
        }
        else {
            this.selected = __spreadArray(__spreadArray([], this.selected, true), [label], false);
        }
    };
    Object.defineProperty(DepartmenAddPlayerModalController.prototype, "applyButtonDisabled", {
        get: function () {
            return ((!this.selected || !this.selected.length) &&
                (!this.player.labels || !this.player.labels.length));
        },
        enumerable: false,
        configurable: true
    });
    DepartmenAddPlayerModalController.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var selections, originalSelections, labelsToAdd, labelsToRemove, player;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        selections = this.selected || [];
                        originalSelections = this.player.labels || [];
                        labelsToAdd = _.differenceBy(selections, originalSelections, '_id');
                        labelsToRemove = _.differenceBy(originalSelections, selections, '_id');
                        player = _.pick(this.player, ['_id', 'firstName', 'lastName']);
                        return [4, this.$q.all(labelsToAdd.map(function (addition) {
                                return _this.$labelsResource.addPlayers({ id: addition._id }, { players: [player] }).$promise;
                            }))];
                    case 1:
                        _a.sent();
                        return [4, this.$q.all(labelsToRemove.map(function (removal) {
                                return _this.$labelsResource.removePlayers({ id: removal._id }, { players: [player] }).$promise;
                            }))];
                    case 2:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    return DepartmenAddPlayerModalController;
}());
angular.module('app.scouting').component('modalDepartmentAddPlayer', {
    templateUrl: 'scouting/components/modals/department-add-player.html',
    controller: DepartmenAddPlayerModalController,
    bindings: {
        $uibModal: '<modalInstance',
        player: '<',
    },
});