class ScoutingReportElementsController {
  private report;
  private elements;
  private idsPrefix;

  constructor(private $scope) {}

  get currentElements() {
    return this.elements || this.report.template.elements;
  }

  getHolder(wrapper, type, id) {
    if (this.idsPrefix) {
      id = `${this.idsPrefix}.${id}`;
    }

    const holder =
      wrapper.values && wrapper.values.find((holder) => holder.type === type && holder.id === id);

    return holder || this.createHolder(wrapper, type, id);
  }

  @CacheReturnValue((context) => context.$scope)
  @OnReturnObjectChangedOnce(
    (context) => context.$scope,
    (context, holder, [wrapper]) => {
      wrapper.values = wrapper.values || [];
      wrapper.values.push(holder);
    },
  )
  private createHolder(wrapper, type, id) {
    return { type, id };
  }
}

angular.module('app.scouting').component('scoutingReportElements', {
  templateUrl: 'scouting/components/report/elements.html',
  controller: ScoutingReportElementsController,
  bindings: {
    report: '<',
    elements: '<',
    idsPrefix: '<',
    editable: '<',
    compact: '<',
    selectedPlayer: '<',
  },
});
