class ScoutingReportPersonFieldController {
  private $searchService;
  private $scoutingService;

  private editable: boolean;
  private params;
  private value;
  private runtimeValue;

  constructor(
    private $scope,
    private $q,
    SearchService,
    ScoutingService,
  ) {
    this.$searchService = SearchService;
    this.$scoutingService = ScoutingService;
  }

  notifyAboutChanges() {
    this.value = this.runtimeValue.filter((item) => !!item);
    this.value.forEach((item) => {
      if (!item._id && !item.temporaryId) {
        item.temporaryId = this.$scoutingService.createTemporaryId();
      }
    });

    this.$scope.$emit('scouting:element:changed');
  }

  $onChanges(changes) {
    const value = _.get(changes, 'value.currentValue', this.value);

    if (changes.selectedPlayer) {
      this.initializeRuntimeValue(value);
    }
  }

  private initializeRuntimeValue(value) {
    this.runtimeValue = (value || []).slice();

    if (this.runtimeValue.length === 0) {
      this.runtimeValue.push(null);
    }
  }

  async searchStaffsAndPlayers(query: string) {
    const [searchResult, temporaryPeople] = await this.$q.all([
      this.$searchService.queryQuickSearch(query, true),
      this.$scoutingService.findTemporaryPeople(query),
    ]);

    const searchList = _.sortBy(
      [...searchResult.staff, ...searchResult.players],
      (item) => item._score,
    ).map((item) => {
      const base = _.pick(item._source, [
        '_id',
        'firstName',
        'lastName',
        'imageUrl',
        'dateOfBirth',
        'temporaryId',
      ]);
      const meta = {
        type: item._type,
        function:
          item._type === 'players'
            ? _.get(item._source, 'latestPlayerStats.playerPosition')
            : _.get(item._source, 'latestStaffStats.staffRole'),
      };

      return Object.assign(base, meta);
    });

    return searchList
      .concat(temporaryPeople)
      .concat([{} as any])
      .filter(
        (item: any) =>
          !this.runtimeValue.some(
            (person) =>
              person &&
              (person._id === item._id ||
                (person.temporaryId && person.temporaryId === item.temporaryId)),
          ),
      );
  }

  addOneMorePerson() {
    this.runtimeValue.push(null);
  }

  resetPerson(index: number) {
    this.runtimeValue[index] = null;
    this.notifyAboutChanges();
  }

  deletePerson(index: number) {
    this.runtimeValue.splice(index, 1);
    this.notifyAboutChanges();
  }
}

angular.module('app.scouting').component('scoutingReportFieldPerson', {
  templateUrl: 'scouting/components/field/person.html',
  controller: ScoutingReportPersonFieldController,
  bindings: { params: '<', value: '=', editable: '<', selectedPlayer: '<' },
});
