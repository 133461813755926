var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var ScoutingDepartmentLabelsDropdownController = (function () {
    function ScoutingDepartmentLabelsDropdownController($uibModal) {
        this.$uibModal = $uibModal;
    }
    ScoutingDepartmentLabelsDropdownController.prototype.createAvailableActions = function () {
        var _this = this;
        if (!this.players.length || !this.availableLabels.length) {
            return (this.actions = null);
        }
        var normalLabels = this.availableLabels.filter(function (label) { return !!label.label; });
        var availableLabelsForAdd = normalLabels.filter(function (label) {
            return _this.players.some(function (player) {
                return player.labels.every(function (playerLabel) { return playerLabel._id !== label._id; });
            });
        });
        this.actions = availableLabelsForAdd.map(function (label) {
            return { initial: false, desired: false, label: label };
        });
    };
    ScoutingDepartmentLabelsDropdownController.prototype.changeAction = function (event, action) {
        switch (action.desired) {
            case null:
                action.desired = true;
                break;
            case true:
                action.desired = false;
                break;
            case false:
                action.desired = action.initial === null ? null : true;
                break;
        }
        event.preventDefault();
    };
    Object.defineProperty(ScoutingDepartmentLabelsDropdownController.prototype, "isApplyPossible", {
        get: function () {
            return (this.actions || []).some(function (item) { return item.desired !== item.initial; });
        },
        enumerable: false,
        configurable: true
    });
    ScoutingDepartmentLabelsDropdownController.prototype.apply = function () {
        var changes = [];
        var actionsToApply = this.actions.filter(function (item) { return item.desired !== item.initial; });
        for (var _i = 0, actionsToApply_1 = actionsToApply; _i < actionsToApply_1.length; _i++) {
            var action = actionsToApply_1[_i];
            switch (action.desired) {
                case true:
                    changes.push.apply(changes, this.findPlayersForLabelAdd(action.label._id));
                    break;
                case false:
                    changes.push.apply(changes, this.findPlayersForLabelRemove(action.label._id));
                    break;
            }
        }
        this.onChange({ $changes: changes });
        actionsToApply.forEach(function (action) {
            action.initial = action.desired;
        });
    };
    ScoutingDepartmentLabelsDropdownController.prototype.findPlayersForLabelAdd = function (needle) {
        return this.players
            .filter(function (item) { return item.labels.every(function (label) { return label._id !== needle; }); })
            .map(function (item) {
            return {
                type: 'add',
                player: _.pick(item.player, ['_id', 'firstName', 'lastName']),
                label: needle,
            };
        });
    };
    ScoutingDepartmentLabelsDropdownController.prototype.findPlayersForLabelRemove = function (needle) {
        return this.players
            .filter(function (item) { return item.labels.some(function (label) { return label._id === needle; }); })
            .map(function (item) {
            return {
                type: 'remove',
                player: _.pick(item.player, ['_id', 'firstName', 'lastName']),
                label: needle,
            };
        });
    };
    ScoutingDepartmentLabelsDropdownController.prototype.createNewLabel = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modal, newLabel, newAction, _reason_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modal = {
                            template: '<modal-department-create-label modal-instance="$ctrl.modalInstance">',
                            controllerAs: '$ctrl',
                            animation: false,
                            backdrop: true,
                            controller: [
                                '$uibModalInstance',
                                function ($uibModalInstance) {
                                    this.modalInstance = $uibModalInstance;
                                },
                            ],
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.$uibModal.open(modal).result];
                    case 2:
                        newLabel = _a.sent();
                        this.availableLabels.push(newLabel);
                        if (!this.actions) {
                            this.actions = [];
                        }
                        newAction = {
                            initial: false,
                            desired: true,
                            label: newLabel,
                        };
                        this.actions.push(newAction);
                        return [2, newLabel];
                    case 3:
                        _reason_1 = _a.sent();
                        return [2, null];
                    case 4: return [2];
                }
            });
        });
    };
    return ScoutingDepartmentLabelsDropdownController;
}());
angular.module('app.scouting').component('scoutingDepartmentLabelsDropdown', {
    templateUrl: 'scouting/components/department-labels-dropdown.html',
    controller: ScoutingDepartmentLabelsDropdownController,
    bindings: { players: '<', availableLabels: '<', onChange: '&', onCreateLabel: '&' },
});