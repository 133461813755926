import { Lineup, PlayerWrapper } from '../../services/ScoutingService';

class ScoutingReportSidebarController {
  private $scoutingService;

  private report;
  private selectedPlayer;
  private editable: boolean;
  private isGameSelected: boolean;
  private onPlayersUpdated: (params: {
    $players: PlayerWrapper[];
    $lineup?: Lineup;
    $teamKind?: string;
  }) => void;
  private onPlayerSelect: (params: { $player: PlayerWrapper }) => void;
  private onGameSelect: () => void;
  private gameReportLineups;

  constructor(
    private $state,
    private $uibModal,
    ScoutingService,
  ) {
    this.$scoutingService = ScoutingService;
  }

  isPositionMatches(position) {
    return (wrapper) => {
      switch (wrapper.player.playerPosition) {
        case 'CENTRE':
        case 'RIGHT_WING':
        case 'LEFT_WING':
        case 'FORWARD':
          return position === 'FORWARD';

        case 'DEFENSEMAN':
        case 'LEFT_DEFENSEMAN':
        case 'RIGHT_DEFENSEMAN':
          return position === 'DEFENSEMAN';

        default:
          return position === wrapper.player.playerPosition;
      }
    };
  }

  async showPlayersPickerModal() {
    const report = this.report;
    const players = angular.copy(this.report.players);

    const modal = {
      template:
        '<modal-players-picker modal-instance="$ctrl.modalInstance" players="$ctrl.players" report="$ctrl.report">',
      controllerAs: '$ctrl',
      size: 'lg',
      backdrop: true,
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.report = report;
          this.players = players;
        },
      ],
    };

    try {
      await this.$uibModal.open(modal).result;
    } catch (_reason) {
      //
    }

    this.onPlayersUpdated({ $players: players });
  }

  async showLineupModal(team) {
    const players = angular.copy(this.report.players);
    const readonly = !this.editable;
    const teamKind =
      (this.report.game.home._id || this.report.game.home.temporaryId) ===
      (team._id || team.temporaryId)
        ? 'home'
        : 'away';
    const gameReportLineup = this.gameReportLineups[teamKind];
    let lineup = this.report.game[teamKind + '_lineup'];
    if (!lineup) {
      lineup = {
        goalies: { GK1: undefined, GK2: undefined, GK3: undefined },
      };
      lineup.lines = [];
      ['1', '2', '3', '4', '5'].forEach((line) => {
        lineup.lines[line] = {};
        ['RD', 'LD', 'RW', 'LW', 'C'].forEach((position) => {
          lineup.lines[line][position] = undefined;
        });
      });
    }

    const modal = {
      template:
        '<modal-lineup modal-instance="$ctrl.modalInstance" team="$ctrl.team" lineup="$ctrl.lineup" players="$ctrl.players" game-report-lineup="$ctrl.gameReportLineup" readonly="$ctrl.readonly">',
      size: 'lg',
      windowClass: 'modal-lineup',
      controllerAs: '$ctrl',
      backdrop: true,
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.team = team;
          this.players = players;
          this.gameReportLineup = gameReportLineup;
          this.lineup = lineup;
          this.readonly = readonly;
        },
      ],
    };

    try {
      await this.$uibModal.open(modal).result;
    } catch (_reason) {
      //
    }

    // santizing the lineup
    ['GK1', 'GK2', 'GK3'].forEach((g) => {
      if (lineup.goalies[g] === undefined) {
        _.unset(lineup, 'goalies.' + g);
      }
    });
    ['1', '2', '3', '4', '5'].forEach((line) => {
      ['RD', 'LD', 'RW', 'LW', 'C'].forEach((position) => {
        if (lineup.lines[line][position] === undefined) {
          _.unset(lineup, `lines.${line}.${position}`);
        }
      });
    });

    this.onPlayersUpdated({ $players: players, $lineup: lineup, $teamKind: teamKind });
  }

  isTeamPlayerSelected(team) {
    return this.selectedPlayer && this.selectedPlayer.team === (team._id || team.temporaryId);
  }

  getTeamTabLabel(team) {
    const playerCount = this.report.players.filter(
      (item) => item.team === (team._id || team.temporaryId) && item.selected,
    ).length;

    return `(${playerCount}) ${team.name}`;
  }
}

angular.module('app.scouting').component('scoutingReportSidebar', {
  templateUrl: 'scouting/components/report/sidebar.html',
  controller: ScoutingReportSidebarController as any,
  bindings: {
    report: '<',
    gameReportLineups: '<',
    loadingGameReportLineups: '<',
    editable: '<',
    selectedPlayer: '<',
    isGameSelected: '<',
    onPlayersUpdated: '&',
    onLineupUpdated: '&',
    onPlayerSelect: '&',
    onGameSelect: '&',
  },
});
