class ScoutingTagsController {
  private $reportResource;

  private templateId;
  private params;
  private list;
  private excluded;
  private availableTags;

  constructor(
    private $scope,
    ScoutingReportResource,
  ) {
    this.$reportResource = ScoutingReportResource;
  }

  $onInit() {
    this.params.tags = this.params.tags || [];

    this.$scope.$watch(
      () => this.templateId,
      () => (this.availableTags = null),
    );

    this.$scope.$watchCollection(
      () => this.list,
      () => this.$scope.$emit('scouting:element:changed'),
    );
  }

  getAvailableTags(searchText) {
    this.list = this.list || [];

    if (!this.availableTags) {
      this.availableTags = this.$reportResource.getTags({ template: this.templateId });
    }

    if (!this.availableTags.$resolved) {
      return this.availableTags.$promise
        .catch(() => {})
        .then(() => this.availableTags.splice(0, 0, ...(this.params.tags || [])))
        .then(() => this.getAvailableTags(searchText));
    }

    const tags = _.uniq(this.availableTags)
      .filter((tag: String) => this.list.indexOf(tag) === -1)
      .filter(
        (tag: String) =>
          !searchText || tag.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1,
      );

    if (this.excluded) {
      return tags.filter((tag) => !this.excluded.includes(tag));
    } else {
      return tags;
    }
  }
}

angular.module('app.scouting').component('scoutingTags', {
  templateUrl: 'scouting/components/general/tags.html',
  controller: ScoutingTagsController,
  bindings: {
    list: '=',
    params: '<',
    templateId: '<',
    editable: '<',
    excluded: '<',
  },
});
