class ScoutingReportTotalFieldController {
  private params;
  private elements: any[];
  private selectedPlayer: any;

  private avgFilter;
  private sumFilter;

  private total: number;
  private shouldDisplayTotal: boolean;
  private aggregations: Array<{
    name: string;
    type: 'average' | 'sum';
    value: number;
  }>;

  constructor($filter) {
    this.avgFilter = $filter('scoutingRatingAvg');
    this.sumFilter = $filter('scoutingRatingSum');
  }

  public $onInit() {
    this.refresh();
  }

  public $onChanges(changes) {
    if (changes.selectedPlayer && !changes.selectedPlayer.isFirstChange()) {
      this.refresh();
    }

    if (changes.editable && !changes.editable.isFirstChange()) {
      this.refresh();
    }
  }

  private refresh() {
    const ids = Object.keys(this.params.includes || {});

    this.aggregations = ids
      .filter((id) => this.params.includes[id])
      .map((id) => this.getElementParams(id))
      .filter((element) => element && element.ratingType === 'number_scale')
      .map((element) => {
        const values = this.getElementValues(element.id)
          ? this.getElementValues(element.id).value
          : null;

        const aggregateType = element.aggregateType || 'average';

        let aggregation;

        switch (aggregateType) {
          case 'average':
            aggregation = this.avgFilter(values, this.getSkills(this.selectedPlayer, element));
            break;

          case 'sum':
            aggregation = this.sumFilter(values, this.getSkills(this.selectedPlayer, element));
            break;
        }

        return {
          name: element.name,
          type: aggregateType,
          value: aggregation,
        };
      });

    this.aggregations = this.aggregations.filter((item) => !!item.value);
    this.shouldDisplayTotal = _.uniq(this.aggregations.map((agg) => agg.type)).length === 1;

    if (this.aggregations.length) {
      const sum = this.aggregations.reduce((accumulator, item) => {
        return accumulator + item.value;
      }, 0);

      switch (this.aggregations[0].type) {
        case 'average':
          this.total = Math.round((sum / this.aggregations.length) * 10) / 10;
          break;

        case 'sum':
          this.total = sum;
          break;
      }
    }
  }

  private getElementParams(id: string) {
    return this.elements.find((element) => element.id === id);
  }

  private getElementValues(id: string) {
    return (
      this.selectedPlayer.values && this.selectedPlayer.values.find((element) => element.id === id)
    );
  }

  private getSkills(player, params) {
    switch (player.player.playerPosition) {
      case 'GOALIE':
        return params.goaliesSkills || params.skills;

      default:
        return params.skills;
    }
  }
}

angular.module('app.scouting').component('scoutingReportFieldTotal', {
  templateUrl: 'scouting/components/field/total.html',
  controller: ScoutingReportTotalFieldController,
  bindings: { params: '<', editable: '<', selectedPlayer: '<', elements: '<' },
});
