interface LabelPlayersAction {
  initial: boolean;
  desired: boolean;
  label: Label;
}

class RemoveDepartmentLabelsDropdownController {
  private players: LabelPlayer[];
  private availableLabels: Label[];
  private selectedLabel: Label;
  private onChange: (args: { $changes: LabelPlayersChange[] }) => void;

  private actions: LabelPlayersAction[];

  constructor(private $uibModal) {}

  createAvailableActions() {
    if (!this.players.length || !this.availableLabels.length || !this.selectedLabel) {
      return (this.actions = null);
    }

    const availableLabelsForRemove = this.availableLabels.filter(
      (label) => label._id === this.selectedLabel._id,
    );

    this.actions = availableLabelsForRemove.map((label) => {
      return { initial: true, desired: true, label: label };
    });
  }

  changeAction(event: MouseEvent, action: LabelPlayersAction) {
    switch (action.desired) {
      case true:
        action.desired = false;
        break;

      case false:
        action.desired = true;
        break;
    }

    event.preventDefault();
  }

  get isApplyPossible() {
    return (this.actions || []).some((item) => item.desired !== item.initial);
  }

  async apply() {
    let confirmationText;
    let modalTitle = 'Confirm Removal';

    if (!this.selectedLabel) {
      // We're on the "All" page - make it very clear this is a complete removal
      modalTitle = 'WARNING: Complete Removal';
      const playerCount = this.players.length;
      confirmationText = `
        <div class="text-danger">
          <strong>WARNING: This will completely remove ${playerCount} player(s) from the Scouting Department!</strong>
        </div>
        <p>Selected players will be removed from ALL lists they belong to.</p>
        <p>This action cannot be undone. Are you sure you want to proceed?</p>
      `;
    } else {
      // We're on a specific label page
      const labelName = this.selectedLabel.label.name;
      const playerCount = this.players.length;
      confirmationText = `Are you sure you want to remove ${playerCount} selected player(s) from the "${labelName}" list?`;
    }

    const modalInstance = this.$uibModal.open({
      size: 'md',
      template:
        '<confirmation-modal modal-instance="$ctrl.modalInstance" translated-message="$ctrl.translatedMessage" modal-title="$ctrl.modalTitle" />',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.translatedMessage = confirmationText;
          this.modalTitle = modalTitle;
        },
      ],
      controllerAs: '$ctrl',
    });

    try {
      await modalInstance.result;

      if (!this.selectedLabel) {
        const changes = this.players.flatMap((player) => {
          return player.labels.map((label) => {
            return { type: 'remove' as const, player: player, label: label._id };
          });
        });

        this.onChange({ $changes: changes });
      } else {
        const changes = this.players.map((player) => {
          return { type: 'remove' as const, player: player, label: this.selectedLabel._id || null };
        });

        this.onChange({ $changes: changes });
      }
    } catch (_e) {
      // Do nothing
    }
  }
}

angular.module('app.scouting').component('scoutingRemoveDepartmentLabelsDropdown', {
  templateUrl: 'scouting/components/remove-department-labels-dropdown.html',
  controller: RemoveDepartmentLabelsDropdownController,
  bindings: { players: '<', availableLabels: '<', selectedLabel: '<', onChange: '&' },
});
