(function () {
  'use strict';

  class ScoutingReportTableFieldController {
    private params;
    private value;
    constructor(
      private $scope,
      private uuid,
    ) {}

    $onInit() {
      if (!this.value) {
        this.value = new Array(this.params.numOfRows).fill({}).map(() => ({ _id: this.uuid.v4() }));
      }
    }
    notifyAboutChanges() {
      this.$scope.$emit('scouting:element:changed');
    }
  }

  angular.module('app.scouting').component('scoutingReportFieldTable', {
    templateUrl: 'scouting/components/field/table.html',
    controller: ScoutingReportTableFieldController,
    bindings: { params: '<', value: '=', editable: '<', compact: '<' },
  });
})();
