import { PlayerRecord } from '../../../player/services/PlayerService';

interface TypeReport {
  player: any;
  reportId: string;
  game: any;
  createdAt: string;
  createdBy: string;
  values: any;
}

interface TypeReportByPlayer {
  [key: string]: TypeReport[];
}

interface TypeSkill {
  $$elId: string;
  $$elType: string;
  $$aggregateType: string;
  $$lookupKey: string;
  $$elScales: any[];
  $$elRatingType: string;
  $$position: string;
  includedForAggregation: Boolean;
}

interface TypeTemplate {
  _id: string;
  elements: any[];
  name: string;
  reportsByPlayer: TypeReportByPlayer;
  allSkills: TypeSkill[];
}

const VALID_ELEMENT_TYPES = ['checkbox', 'radio_button', 'total', 'datepicker'];

class ReportsComparisionModalController {
  private $uibModal;
  private players: PlayerRecord[];

  private reports;

  private templates: any[];

  private colorizeRating: boolean;
  private showComments: boolean;
  private showAllElements: boolean;
  private showGraphView: boolean;

  private $promise: any;

  private $domCache: any;
  private avgFilter;
  private sumFilter;
  private colorFilter;
  private showPublicReports;

  public constructor(
    private $element,
    private $filter,
    private $q,
    private ScoutingReportResource,
    private $sce,
  ) {
    this.syncScroll = this.syncScroll.bind(this);
    this.$domCache = {};
    this.colorizeRating = true;
    this.showComments = true;
    this.showAllElements = true;
    this.showGraphView = false;
    this.avgFilter = $filter('scoutingRatingAvg');
    this.sumFilter = $filter('scoutingRatingSum');
    this.colorFilter = $filter('scColorTransition');
    this.showPublicReports = true;
  }

  public $onInit() {
    this.fetchReports();
  }

  fetchReports(forceFetch = false) {
    if (!this.reports || !this.reports.length || forceFetch) {
      const params = {
        skip: 0,
        limit: 1000,
        sort: 'createdAt',
        direction: -1,
        showPublic: this.showPublicReports ? '1' : '-1',
      };
      const filters = [
        {
          criterion: 'player',
          field: 'players.player',
          negative: false,
          value: {
            or: this.players.map((player) => ({ _id: player._id })),
          },
        },
      ];
      this.$promise = this.ScoutingReportResource.query(
        Object.assign({}, params, { type: 'players' }),
        {
          filters,
        },
      ).$promise.then(
        (reports) =>
          (this.reports = reports.map((report) => {
            report.players = [report.players];
            return report;
          })),
      );
    } else {
      this.$promise = this.$q.resolve();
    }

    this.$promise.then(() => {
      const reportsByTemplates = _.groupBy(this.reports, 'template._id');

      // We need all the versions of all templates to aggregate the skill tables above
      const templateSnapshotsById: any = _.groupBy(
        this.reports.map((rep) => rep.template),
        '_id',
      );

      // We need to unique the template by Id because a template only show once on the report
      const uniqTemplates = _.uniqBy(
        this.reports.map((rep) => rep.template),
        '_id',
      );

      this.templates = uniqTemplates.map((tpl: TypeTemplate) => {
        const reports = reportsByTemplates[tpl._id];

        tpl.reportsByPlayer = this.createReportsByPlayer(reports);

        // Get all skills from all snapshots of a template
        tpl.allSkills = this.getAllTplSkills(templateSnapshotsById[tpl._id]);

        return tpl;
      });

      this.templates.map((tpl) => {
        tpl.isVisible =
          tpl.allSkills.length > 0 ||
          (this.showComments
            ? (tpl.elements || []).some((el) => el.type === 'text_field')
            : false) ||
          (this.showAllElements ? (tpl.elements || []).length : false);
        return tpl;
      });
    });
  }

  onToggleIncludeFeatureReports() {
    this.fetchReports(true);
  }

  private getAllTplElements(template: TypeTemplate) {
    return _.flatten(
      (template.elements || []).map((el) =>
        el.type === 'template_chapter'
          ? el.elements.map((childEl) => ({
              ...childEl,
              id: `${el.id}.${childEl.id}`,
            }))
          : [el],
      ),
    );
  }

  private getAllTplSkills(templateSnapshots: TypeTemplate[]) {
    return _.chain(templateSnapshots)
      .map((el) => this.getAllTplElements(el))
      .flatten()
      .filter((el: any) => el.type === 'skill_table')
      .map(this.mapElementToSkills)
      .flatten()
      .uniqWith((a, b) => {
        // Create the uniqueId for compare to filter out the duplicate skills
        return a.$$elId + a.$$lookupKey + a.$$position === b.$$elId + b.$$lookupKey + b.$$position;
      })
      .value();
  }

  private mapElementToSkills(el) {
    let skills = el.skills.map((skill: any) => ({
      ...skill,
      $$aggregateType: el.aggregateType,
      $$lookupKey: skill.name,
      $$elScales: el.scales,
      $$elType: el.type,
      $$elId: el.id,
      $$elRatingType: el.ratingType,
      $$position: el.goaliesCustom ? 'skater' : 'skater+goalie',
    }));

    if (el.goaliesCustom) {
      skills = skills.concat(
        (el.goaliesSkills || []).map((skill: any) => ({
          ...skill,
          $$aggregateType: el.aggregateType,
          $$lookupKey: skill.name,
          $$elScales: el.scales,
          $$elType: el.type,
          $$elId: el.id,
          $$elRatingType: el.ratingType,
          $$position: 'goalie',
        })),
      );
    }

    if (el.allowOverallComment) {
      skills.unshift({
        name: `* Overall ${el.name}`,
        $$lookupKey: '__overal__', // NOTE!!! this is a typo when implementing the reports, should be __overall__
        $$elScales: null,
        $$elType: el.type,
        $$elId: el.id,
        $$elRatingType: 'text_comments',
        $$position: 'skater+goalie',
      });
    }

    if (el.allowOverallRating) {
      skills.unshift({
        name: `* Overall ${el.name}`,
        $$lookupKey: '__overall__',
        $$elScales: el.overallRatingScales,
        $$elType: el.type,
        $$elId: el.id,
        $$elRatingType: el.overallRatingType,
        $$position: 'skater+goalie',
      });
    }

    if (el.aggregateData) {
      const aggregateColumn: any = {
        $$elScales: el.scales,
        $$elType: el.type,
        $$elId: el.id,
        $$elRatingType: el.ratingType,
        $$position: 'skater+goalie',
        includedForAggregation: false,
      };
      if (el.aggregateType === 'average') {
        aggregateColumn.$$lookupKey = '__average__';
        aggregateColumn.description = aggregateColumn.name = 'Average';
      }

      if (el.aggregateType === 'sum') {
        aggregateColumn.$$lookupKey = '__sum__';
        aggregateColumn.description = aggregateColumn.name = 'Sum';
      }
      skills.unshift(aggregateColumn);
    }

    return skills;
  }

  private createReportsByPlayer(reports): TypeReportByPlayer {
    const reportsByPlayer: TypeReportByPlayer = {};

    reports.forEach((report: any) => {
      report.players.forEach((player) => {
        if (player.selected) {
          reportsByPlayer[player.player._id] = reportsByPlayer[player.player._id] || [];

          const valueHolder = {
            ...player,
            reportId: report._id,
            isPublic: report.isPublic,
            organization: report.organization,
            game: report.game,
            createdAt: report.createdAt,
            createdBy: report.createdBy,
            values: {},
          };

          const allTplSnapshotElements = this.getAllTplElements(report.template);

          valueHolder.values = (player.values || [])
            .filter((value) => value.type === 'skill_table')
            .reduce((acc, value) => {
              let skillMap = _.keyBy(value.value, 'skill');

              const elementSnapshot: any = allTplSnapshotElements.find(
                (el: any) => el.id === value.id && el.type === value.type,
              );

              skillMap = _.mapValues(skillMap, (valueItem: any, key: string) => {
                const val = valueItem.value;

                if (typeof val === 'undefined' || val === null || val === '') {
                  valueItem.__color = '#fff';
                } else if (key === '__overall__') {
                  const scales = elementSnapshot.overallRatingScales || elementSnapshot.scales;

                  valueItem.__color =
                    scales && scales.length
                      ? this.colorFilter(
                          this.getValueIndex(scales, val),
                          scales.length,
                          elementSnapshot.reverseRatingColor,
                          0.7,
                        )
                      : '#fff';
                } else if (elementSnapshot.ratingType === 'data_comments') {
                  valueItem.__color = '#fff';
                } else {
                  valueItem.__color = this.colorFilter(
                    this.getValueIndex(elementSnapshot.scales, val),
                    elementSnapshot.scales.length,
                    elementSnapshot.reverseRatingColor,
                    0.7,
                  );
                }

                return valueItem;
              });

              if (elementSnapshot.aggregateData) {
                const ratingValues = _.toArray(skillMap);
                const snapshotSkills = elementSnapshot.goaliesCustom
                  ? elementSnapshot.goaliesSkills.concat(elementSnapshot.skills)
                  : elementSnapshot.skills;

                const ratingMeasures = (snapshotSkills || []).filter(
                  (s) => s.includedForAggregation,
                );

                const scales = elementSnapshot.scales || [];

                const avgValue = this.avgFilter(ratingValues, ratingMeasures);
                const sortedScales: { val: number }[] = _.sortBy(scales, 'val');
                // Both average and sum uses the same average color
                const colorIndex = this.getAverageColorIndex(avgValue, sortedScales);
                const __color =
                  _.isNumber(colorIndex) && scales
                    ? this.colorFilter(colorIndex, scales.length, false, 0.7)
                    : '#fff';

                if (elementSnapshot.aggregateType === 'average') {
                  skillMap.__average__ = {
                    __color,
                    skill: 'Average',
                    value: avgValue,
                  };
                }
                if (elementSnapshot.aggregateType === 'sum') {
                  skillMap.__sum__ = {
                    __color,
                    skill: 'Sum',
                    value: this.sumFilter(ratingValues, ratingMeasures),
                  };
                }
              }

              acc[`${value.type}-${value.id}`] = skillMap;
              return acc;
            }, {});

          valueHolder.values.textFields = (player.values || [])
            .filter((value) => value.type === 'text_field')
            .map((textField) => {
              const elementDef = report.template.elements.find((el) => el.id === textField.id);
              if (elementDef) {
                textField.name = elementDef.name;
              }
              return textField;
            });

          valueHolder.values.allElements = (player.values || [])
            .filter((value) => VALID_ELEMENT_TYPES.includes(value.type))
            .map((element) => {
              const elementDef = report.template.elements.find((el) => el.id === element.id);
              if (elementDef) {
                element.name = elementDef.name;
              }
              return element;
            });

          reportsByPlayer[player.player._id].push(valueHolder);
        }
      });
    });

    return reportsByPlayer;
  }

  private getAverageColorIndex = (avgValue: number, sortedScales: { val: number }[]): number => {
    if (sortedScales.length == 0) {
      return 0;
    }
    if (!_.isNumber(avgValue)) {
      return null;
    }

    let nearestIdx = _.findLastIndex(sortedScales, (scale) => scale.val <= avgValue);

    if (nearestIdx < sortedScales.length - 1) {
      const middleValue =
        (sortedScales[nearestIdx + 1].val - sortedScales[nearestIdx].val) / 2 +
        sortedScales[nearestIdx].val;
      if (avgValue >= middleValue) {
        nearestIdx = nearestIdx + 1;
      }
    }

    return nearestIdx;
  };

  public onShowCommentsChanged() {
    this.templates.map((tpl) => {
      tpl.isVisible =
        tpl.allSkills.length > 0 ||
        (this.showComments ? (tpl.elements || []).some((el) => el.type === 'text_field') : false);
      return tpl;
    });
  }

  public onShowAllElementsChanged() {
    this.templates.map((tpl) => {
      tpl.isVisible =
        tpl.allSkills.length > 0 ||
        (this.showComments ? (tpl.elements || []).some((el) => el.type === 'text_field') : false) ||
        (this.showAllElements ? (tpl.elements || []).length : false);
      return tpl;
    });
  }

  public $postLink() {
    document.addEventListener('scroll', this.syncScroll, true);
  }

  public $onDestroy() {
    document.removeEventListener('scroll', this.syncScroll, true);
  }

  public syncScroll(e) {
    const $target = angular.element(e.target);

    if (!$target.hasClass('table-scrolling-body')) {
      return;
    }

    const templateId = $target.attr('data-template-id');
    const leftDistance = $target.scrollLeft();

    let $scrollingElements = this.$domCache[templateId];

    if (!$scrollingElements) {
      this.$domCache[templateId] = this.$element.find(`[data-template-id="${templateId}"]`);
      $scrollingElements = this.$domCache[templateId];
    }

    $scrollingElements.scrollLeft(leftDistance);
  }

  protected getValueIndex(scales, value) {
    return (scales || []).findIndex((item) => item.val === value);
  }

  public close() {
    this.$uibModal.dismiss();
  }

  private skillFilter(player) {
    return (skill) => {
      if (player.playerPosition === 'GOALIE') {
        return skill.$$position === 'goalie' || skill.$$position === 'skater+goalie';
      }

      return skill.$$position === 'skater' || skill.$$position === 'skater+goalie';
    };
  }

  /**
   * Get the tooltip for the skill tables
   * @param  {string} lookupKey The title of the column
   * @param  {string} elId      The element id, skill belongs to an element, in case a template has many elements
   *                            we need the elId to figure out which element the skill belongs to
   *                            to show the right tooltip
   * @param  {value}  value     The value of the hovered cell
   * @param  {array}  allSkills The skill array to get all the aggregated skills for the tooltip
   * @return {string}
   */
  private getTooltip(lookupKey, elId, value, allSkills) {
    if (!value) {
      return '-';
    }

    // Skills with these lookupKeys is not the original skills, we added it on $onInit for displaying only
    if (lookupKey === '__average__' || lookupKey === '__sum__') {
      const aggregatedSkills = allSkills.filter(
        (s) =>
          s.includedForAggregation &&
          s.$$elId === elId &&
          s.$$lookupKey !== lookupKey &&
          s.$$aggregateType === lookupKey.replace(/_/g, ''),
      );

      const skillNames = aggregatedSkills.map((s) => s.name).join(', ');

      return this.$filter('scTranslate')(`scouting.Skill_table_${lookupKey}_skill_tooltip`, {
        number: value,
        skillNames,
      });
    }

    return value;
  }

  /**
   * Return the report value object to simplify the markup on html
   */
  private getReportValue(playerReport: TypeReportByPlayer, skill: TypeSkill): TypeReport {
    try {
      const { $$elType, $$elId, $$lookupKey } = skill;
      return playerReport.values[$$elType + '-' + $$elId][$$lookupKey];
    } catch {
      return null;
    }
  }

  private getTooltipHTML(value) {
    return this.$sce.trustAsHtml(value);
  }
}

angular.module('app.scouting').component('modalReportsComparision', {
  templateUrl: 'scouting/components/modals/reports-comparision.html',
  controller: ReportsComparisionModalController,
  bindings: {
    reports: '<',
    players: '<',
    $uibModal: '<modalInstance',
  },
});
