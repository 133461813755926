class ScoutingReportControlsController {
  private $historyService;

  private report;
  private user;
  private editable;

  constructor(
    private $state,
    private $uibModal,
    private UserService,
    private ScoutingService,
    History,
    private $scope,
    private ACLService,
  ) {
    this.$historyService = History;
  }

  public $onInit() {
    this.user = this.UserService.getUser();
  }

  private onCompletedChanged() {
    this.ScoutingService.triggerChangedReportSave(this.report);

    if (this.report.completed) {
      this.editable = false;
    }
  }

  private edit() {
    this.editable = true;

    if (this.report.completed) {
      this.report.completed = false;
      this.ScoutingService.triggerChangedReportSave(this.report);
    }
  }

  delete() {
    const modalInstance = this.$uibModal.open({
      size: 'sm',
      template: '<modal-report-delete modal-instance="$ctrl.modalInstance">',
      controllerAs: '$ctrl',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
        },
      ],
    });

    modalInstance.result
      .then(() => (this.report.$promise = this.report.$delete()))
      .then(() => this.$state.go('root.scouting.player_reports', null, { location: 'replace' }));
  }

  close() {
    if (window.history.length) {
      window.history.back();
    } else {
      this.$state.go('root.scouting.player_reports', null);
    }
  }

  togglePublicReport() {
    this.report.isPublic = !this.report.isPublic;
    this.ScoutingService.triggerChangedReportSave(this.report);
  }

  get canEditReport() {
    if (this.report.organization.id !== this.user.account._id) {
      return false;
    }
    return (
      this.ACLService.hasAccountPermissions(['organization-admin-access']) ||
      this.report.createdBy.userId === this.user._id
    );
  }
}

angular.module('app.scouting').component('scoutingReportControls', {
  templateUrl: 'scouting/components/report/controls.html',
  controller: ScoutingReportControlsController,
  bindings: {
    report: '<',
    editable: '=',
  },
});
