interface LabelPlayersAction {
  initial: boolean;
  desired: boolean;
  label: Label;
}

interface LabelPlayersChange {
  type: 'add' | 'remove';
  player: LabelPlayer;
  label: string;
}

class ScoutingDepartmentLabelsDropdownController {
  private players: LabelPlayer[];
  private availableLabels: Label[];
  private onChange: (args: { $changes: LabelPlayersChange[] }) => void;
  private onCreateLabel: () => Promise<Label>;

  private actions: LabelPlayersAction[];

  constructor(private $uibModal) {}

  createAvailableActions() {
    if (!this.players.length || !this.availableLabels.length) {
      return (this.actions = null);
    }

    const normalLabels = this.availableLabels.filter((label) => !!label.label);

    const availableLabelsForAdd = normalLabels.filter((label) =>
      this.players.some((player) =>
        player.labels.every((playerLabel) => playerLabel._id !== label._id),
      ),
    );

    this.actions = availableLabelsForAdd.map((label) => {
      return { initial: false, desired: false, label: label };
    });
  }

  changeAction(event: MouseEvent, action: LabelPlayersAction) {
    switch (action.desired) {
      case null:
        action.desired = true;
        break;

      case true:
        action.desired = false;
        break;

      case false:
        action.desired = action.initial === null ? null : true;
        break;
    }

    event.preventDefault();
  }

  get isApplyPossible() {
    return (this.actions || []).some((item) => item.desired !== item.initial);
  }

  apply() {
    const changes = [];

    const actionsToApply = this.actions.filter((item) => item.desired !== item.initial);

    for (const action of actionsToApply) {
      switch (action.desired) {
        case true:
          changes.push(...this.findPlayersForLabelAdd(action.label._id));
          break;

        case false:
          changes.push(...this.findPlayersForLabelRemove(action.label._id));
          break;
      }
    }

    this.onChange({ $changes: changes });

    actionsToApply.forEach((action) => {
      action.initial = action.desired;
    });
  }

  private findPlayersForLabelAdd(needle: string) {
    return this.players
      .filter((item) => item.labels.every((label) => label._id !== needle))
      .map((item) => {
        return {
          type: 'add',
          player: _.pick(item.player, ['_id', 'firstName', 'lastName']),
          label: needle,
        };
      });
  }

  private findPlayersForLabelRemove(needle: string) {
    return this.players
      .filter((item) => item.labels.some((label) => label._id === needle))
      .map((item) => {
        return {
          type: 'remove',
          player: _.pick(item.player, ['_id', 'firstName', 'lastName']),
          label: needle,
        };
      });
  }

  async createNewLabel() {
    const modal = {
      template: '<modal-department-create-label modal-instance="$ctrl.modalInstance">',
      controllerAs: '$ctrl',
      animation: false,
      backdrop: true,
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
        },
      ],
    };

    try {
      const newLabel = await this.$uibModal.open(modal).result;

      this.availableLabels.push(newLabel);

      if (!this.actions) {
        this.actions = [];
      }

      const newAction = {
        initial: false,
        desired: true,
        label: newLabel,
      };

      this.actions.push(newAction);

      return newLabel;
    } catch (_reason) {
      return null;
    }
  }
}

angular.module('app.scouting').component('scoutingDepartmentLabelsDropdown', {
  templateUrl: 'scouting/components/department-labels-dropdown.html',
  controller: ScoutingDepartmentLabelsDropdownController,

  bindings: { players: '<', availableLabels: '<', onChange: '&', onCreateLabel: '&' },
});
