import { Filter } from '../../services/ScoutingFilterService';

interface FilterStatistics {
  reports: { total: number; filtered: number };
  players: { total: number; filtered: number };
}

class ScoutingFiltersController {
  private $state;
  private $reportResource;
  private $filterService;

  private viewType: 'players' | 'reports';
  private filters: Filter[];
  private onFiltersChanged: () => void;

  private filterStatisticsPromise: Promise<FilterStatistics>;
  private filterStatisticsData: FilterStatistics;
  private showPublic;

  constructor($state, ScoutingReportResource, ScoutingFilterService) {
    this.$state = $state;
    this.$reportResource = ScoutingReportResource;
    this.$filterService = ScoutingFilterService;
  }

  $onChanges(changes) {
    if (changes.viewType || changes.showPublic) {
      this.updateFiltersStatistics();
    }
  }

  update() {
    this.updateFiltersStatistics();
    this.onFiltersChanged();

    this.$state.transitionTo(
      this.$state.$current.name,
      this.$filterService.serialize(this.filters),
      {
        location: 'replace',
        inherit: false,
        notify: false,
      },
    );
  }

  updateFiltersStatistics() {
    this.filterStatisticsPromise = this.$reportResource.getFiltersStatistics(
      { type: this.viewType, showPublic: this.showPublic ? 1 : -1 },
      { filters: this.filters },
    ).$promise;
    this.filterStatisticsPromise.then((result) => (this.filterStatisticsData = result));
  }
}

angular.module('app.scouting').component('scoutingFilters', {
  templateUrl: 'scouting/components/filters/filters.html',
  controller: ScoutingFiltersController,
  bindings: {
    viewType: '<',
    filters: '<',
    onFiltersChanged: '&',
    showPublic: '<',
  },
});
