class ScoutingReportGameStatsController {
  private report;
  private selectedPlayer;

  private playerGameStats;
  private ts;
  private $promise;
  private loadingKey;

  constructor(
    private $q,
    private GameService,
    private GameUtils,
  ) {}

  $onChanges(changes) {
    const changesReport = _.get(changes, 'report.currentValue');
    const changesPlayer = _.get(changes, 'selectedPlayer.currentValue');

    if (changesReport && changesPlayer) {
      this.ts = Date.now();
      this.fetchStats(changesReport, changesPlayer, this.ts);
    } else if (changesReport) {
      this.ts = Date.now();
      this.fetchStats(changesReport, this.selectedPlayer, this.ts);
    } else if (changesPlayer) {
      this.ts = Date.now();
      this.fetchStats(this.report, changesPlayer, this.ts);
    }
  }

  fetchStats(report, selectedPlayer, ts) {
    this.playerGameStats = null;

    if (!report.game || !selectedPlayer) {
      this.$promise = this.$q.resolve();
      return;
    }

    this.$promise = this.GameService.getPlayerStats(selectedPlayer.player._id, {
      isSkater: this.isSkater(selectedPlayer.player),
      // angularJS $resource automatic parse ISO861 date string to Date object
      // need to use the utc() here to be not affected by timezone
      dates: moment(report.game.date).utc().format('YYYY-MM-DD'),
    }).then((stats) => {
      // if the timestamp for this fetch doesn't match the timestamp on this component
      // we just silently ignore the fetch result
      if (ts !== this.ts) {
        return;
      }

      const playerGameStats = stats[0];

      if (!playerGameStats) {
        return;
      }

      this.playerGameStats = playerGameStats;
    });
  }

  isSkater(player: any) {
    return player && player.playerPosition !== 'GOALIE';
  }

  playEvents({ $gameEvents, $title, $key }) {
    if (!this.playerGameStats) {
      return;
    }
    const gameTitle = `${this.playerGameStats.date}. ${this.playerGameStats.home.name} - ${this.playerGameStats.away.name}`;
    const playerName = `${this.playerGameStats.playerStat.fullName} (${this.playerGameStats.playerStat.jerseyNumber})`;
    const title = `${gameTitle} // ${playerName} // ${$title}`;

    this.loadingKey = $key;
    const eventIds = $gameEvents.map((evt) => this.GameUtils.extractStatEvents(evt).eventId);
    this.GameService.fetchGameEvents(eventIds)
      .then((gameEvents) => {
        this.GameService.checkStaleEventData(eventIds, gameEvents);
        this.GameService.openVirtualClipViewer(title, gameEvents);
      })
      .finally(() => {
        this.loadingKey = '';
      });
  }

  isPlayable(statName: 'G' | 'AS' | 'SOG' | 'TOI' | 'GA' | 'SVS') {
    return (
      !!this.playerGameStats.isVideoAvailable &&
      this.playerGameStats.playerStat.events[statName] &&
      this.playerGameStats.playerStat.events[statName].length > 0 &&
      this.playerGameStats.playerStat.events[statName].some(
        (evt) => this.GameUtils.extractStatEvents(evt).isPlayable,
      )
    );
  }
}

angular.module('app.scouting').component('scoutingReportGameStats', {
  templateUrl: 'scouting/components/report/game-stats.html',
  controller: ScoutingReportGameStatsController,
  bindings: {
    report: '<',
    selectedPlayer: '<',
  },
});
