import { PlayerRecord } from '../../player/services/PlayerService';
import { TeamRecord } from '../../team/services/TeamService';

export interface RosterItem {
  team: string;
  jerseyNumber: number;
  player: any;

  selected: boolean;
  lineup: boolean | null;
}

// export interface Report {
//     season: number;
//     players: RosterItem[];

//     tags?: string[];
//     game?: {
//         date: Date,
//         home: TeamRecord,
//         away: TeamRecord,
//         league: any,
//     };
// }

export interface LineupItem {
  RD?: string;
  LD?: string;
  RF?: string;
  C?: string;
  LF?: string;
  CD?: string;

  scheme?: { we: number; they: number };
}

export interface Lineup {
  goalies: { GK1: string; GK2: string; replacements: any; GK3: string; secondaryReplacement };

  lines: LineupItem[];
  power: LineupItem[];
  box: LineupItem[];
  even: LineupItem[];

  missing: Array<{ player: string; reason: string }>;
}

export interface ReportRecord {
  _id: string;
  players: PlayerWrapper[];
  teams: {
    _id?: string;
    temporaryId?: string;
    name: string;
  }[];
  game: {
    home: {
      _id: string;
      name: string;
      imageUrl: string;
    };
    away: {
      _id: string;
      name: string;
      imageUrl: string;
    };
    score_home: string;
    score_away: string;
    scores: string;
  };
}

export interface PlayerWrapper {
  selected: boolean;
  player: {
    _id: string;
  };
  values: any[];
  tags: any[];
}

class ScoutingService {
  private $scoutingReportResource;
  private $pendingActionsService;
  private temporaryTeamsEndpoint;
  private temporaryPeopleEndpoint;
  private temporaryLeaguesEndpoint;
  private scoutingSheetsEndpoint;
  private scoutingReportEndpoint;

  constructor(
    private $uibModal,
    private $state,
    private $http,
    SCConfiguration,
    // tslint:disable-next-line
    ScoutingReportResource,
    PendingActionsService,
  ) {
    this.$scoutingReportResource = ScoutingReportResource;
    this.$pendingActionsService = PendingActionsService;
    this.temporaryTeamsEndpoint = SCConfiguration.getEndpoint() + '/api/scouting/temporary-teams';
    this.temporaryLeaguesEndpoint =
      SCConfiguration.getEndpoint() + '/api/scouting/temporary-leagues';
    this.temporaryPeopleEndpoint = SCConfiguration.getEndpoint() + '/api/scouting/temporary-people';
    this.scoutingSheetsEndpoint = SCConfiguration.getEndpoint() + '/api/scouting/sheets';
    this.scoutingReportEndpoint = SCConfiguration.getEndpoint() + '/api/scouting/reports';
  }

  public requestReportCreation() {
    this.openReportCreationModal({});
  }

  public requestLeagueReportCreation(league: any) {
    this.openReportCreationModal({
      league,
    });
  }

  public requestTeamReportCreation(team: TeamRecord, league: any) {
    this.openReportCreationModal({
      team,
      league,
    });
  }

  public requestPlayerReportCreation(playerId: string, team: TeamRecord, league: any) {
    this.openReportCreationModal({
      team,
      league,
      playerIds: [playerId],
    });
  }

  public requestAddingToDepartment(player: any, callback?: () => void) {
    const modal = this.$uibModal.open({
      template:
        '<modal-department-add-player modal-instance="$ctrl.modalInstance" player="$ctrl.player" />',
      controllerAs: '$ctrl',
      animation: false,
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.player = player;
        },
      ],
    });

    modal.result.then(() => (callback ? callback() : angular.noop())).catch(angular.noop);
  }

  // passing reports = null when we want the modal to load all player's reports
  public compareReports(players: PlayerRecord[], reports: any[]) {
    const modal = this.$uibModal.open({
      template:
        '<modal-reports-comparision modal-instance="$ctrl.modalInstance" players="$ctrl.players" reports="$ctrl.reports" />',
      animation: false,
      windowClass: 'modal-fullscreen',
      openedClass: 'reports-comparision-modal-open',
      controllerAs: '$ctrl',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.players = players;
          this.reports = reports;
        },
      ],
    });

    modal.result.then(angular.noop).catch(angular.noop);
  }

  public showCommentsBoard(players: PlayerRecord[]) {
    const modal = this.$uibModal.open({
      template: '<comments-board modal-instance="$ctrl.modalInstance" players="$ctrl.players"/>',
      windowClass: 'modal-fullscreen',
      openedClass: 'reports-comparision-modal-open',
      controllerAs: '$ctrl',
      animation: false,
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.players = players;
        },
      ],
    });

    modal.result.then(angular.noop).catch(angular.noop);
  }

  private openReportCreationModal(autoselect) {
    const modal = this.$uibModal.open({
      template:
        '<modal-report-setup modal-instance="$ctrl.modalInstance" autoselect="$ctrl.autoselect">',
      controllerAs: '$ctrl',
      animation: false,
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.autoselect = autoselect;
        },
      ],
    });

    modal.result.then((id) => {
      this.$state.go('root.scouting.report', { id, edit: true });
    }, angular.noop);
  }

  public findTemporaryTeams(query) {
    return this.$http
      .get(this.temporaryTeamsEndpoint, { params: { query } })
      .then((response) => response.data);
  }

  public findTemporaryLeagues(query) {
    return this.$http
      .get(this.temporaryLeaguesEndpoint, { params: { query } })
      .then((response) => response.data);
  }

  public findTemporaryPeople(query) {
    return this.$http
      .get(this.temporaryPeopleEndpoint, { params: { query } })
      .then((response) => response.data);
  }

  public getTemporaryRoster(temporaryTeamId) {
    return this.$http
      .get(`${this.temporaryTeamsEndpoint}/${temporaryTeamId}/roster`)
      .then((response) => response.data);
  }

  public getScoutingSheet() {
    return this.$http.get(this.scoutingSheetsEndpoint).then((response) => response.data);
  }

  public addColumnToScoutingSheet(sheetId, { columnName, template }) {
    return this.$http
      .post(this.scoutingSheetsEndpoint + `/${sheetId}/add-column`, {
        columnName,
        template,
      })
      .then((response) => response.data);
  }

  public removeColumnFromScoutingSheet(sheetId, template) {
    return this.$http
      .post(this.scoutingSheetsEndpoint + `/${sheetId}/remove-column`, {
        template,
      })
      .then((response) => response.data);
  }

  public getScoutingSheetComments(sheetId, playerIds, templateIds) {
    return this.$http
      .post(this.scoutingSheetsEndpoint + `/${sheetId}/get-comments`, {
        playerIds,
        templateIds,
      })
      .then((response) => response.data);
  }

  public createScoutingSheetComment(sheetId, { player, template, comment }) {
    return this.$http
      .post(this.scoutingSheetsEndpoint + `/${sheetId}/add-comment`, {
        player,
        template,
        comment,
      })
      .then((response) => response.data);
  }

  public grantOrRevokeScoutingSheetAccess(sheetId, { add, remove }) {
    return this.$http
      .post(this.scoutingSheetsEndpoint + `/${sheetId}/members`, {
        add,
        remove,
      })
      .then((response) => response.data);
  }

  public updateScoutingSheetComment(sheetId, comment) {
    return this.$http
      .post(this.scoutingSheetsEndpoint + `/${sheetId}/update-comment`, comment)
      .then((response) => response.data);
  }

  public createTemporaryId() {
    return `${Date.now()}-${Math.round(Math.random() * 1000000)}`;
  }

  public fetchReport(id: string) {
    const instance = this.$scoutingReportResource.get({ id });

    instance.$promise.then((report) => {
      report.$$latestSavedState = angular.toJson(report);
    });

    return instance;
  }

  public triggerChangedReportSave(report) {
    if (report._id == null || angular.toJson(report) === report.$$latestSavedState) {
      return;
    }

    this.$pendingActionsService.start('scouting:report:save', 'Saving the report...');

    this.$scoutingReportResource
      .update(this.removeTemporaryFields(report))
      .$promise.then((response) => {
        report.updatedAt = response.updatedAt;
        report.updatedBy = response.updatedBy;
        report.$$latestSavedState = angular.toJson(report);

        this.$pendingActionsService.finish('scouting:report:save');
      });
  }

  public chooseSelectedPlayer(report) {
    let player;

    if (!player && report.primaryPlayerId) {
      player = report.players.find(
        (item) => item.player._id === report.primaryPlayerId && item.selected,
      );
    }

    if (!player && report.primaryTeam) {
      player = this.chooseTeamPlayer(report, report.primaryTeam);
    }

    if (!player) {
      player = report.teams
        .map((team) => this.chooseTeamPlayer(report, team))
        .filter((item) => !!item)[0];
    }

    return player;
  }

  public chooseTeamPlayer(report, team: TeamRecord) {
    return report.players.filter(
      (item) => item.team === (team._id || team.temporaryId) && item.selected,
    )[0];
  }

  private removeTemporaryFields(report) {
    report = angular.copy(report);

    for (const wrapper of report.players) {
      delete wrapper.freshPlayerInstance;
    }

    return report;
  }
  public getTaggingListById(reportId, playlistId) {
    return this.$http
      .get(`${this.scoutingReportEndpoint}/${reportId}/playlist/${playlistId}`)
      .then((response) => response.data);
  }

  public recordScoutingReportTracking(player: any, scoutingReportId: string) {
    return this.$http.post(this.scoutingReportEndpoint + '/tracking', {
      scoutingReportId: scoutingReportId,
      player: {
        _id: player._id,
        name: player.firstName + ' ' + player.lastName,
      },
      deviceInfo: {
        type: 'web',
        userAgent: window.navigator.userAgent,
      },
      viewMode: 'full',
    });
  }
  public updateScoutingReportTracking(trackingRecordId, player: any) {
    return this.$http.put(this.scoutingReportEndpoint + '/tracking', {
      recordId: trackingRecordId,
      player: {
        _id: player._id,
        name: player.firstName + ' ' + player.lastName,
      },
    });
  }
  public calculateGameReportLineup(gameReport, gameLineupReport) {
    /**
     * if "isRandomLineups" is set (true | false), we respect this flag
     * if "isRandomLineups" is not set (undefined | null)
     *  => old record before we added the "isRandomLineups" flag, it could be:
     *     - not random (~95% of the time) - Nikita team did the lineups correctly
     *     - random (~5% - we can't be sure) - Nikita team put in the random lineup, but before we have the support of "isRandomLineups"
     *  => when "isRandomLineups" field is missing, we try to work out the lineups from flashscore report. If no flashscore report is found, we consider it as random lineups (isRandomLineups=True)
     * if "isRandomLineups" and position, we show forwards, defensemen, goalie
     * if "isRandomLineups", we show skaters, goalie
     */
    if (!gameReport) {
      return;
    }
    let currentReport = gameLineupReport;
    if (
      gameReport.isRandomLineups === true ||
      gameReport.isRandomLineups === false ||
      !gameLineupReport
    ) {
      currentReport = gameReport;
    }
    if (!currentReport) {
      return;
    }
    let players = [];
    let homePlayers = [];
    let awayPlayers = [];
    // players comming from @anh events importer
    // the team of the player is the externalId
    if (currentReport.players && currentReport.players.length) {
      players = currentReport.players;
      homePlayers = players.filter((player) => player.team === currentReport.home.id);
      awayPlayers = players.filter((player) => player.team === currentReport.away.id);
    }
    // lineups comming from @Denys lineups importer
    // the team of the player is scId
    else if (currentReport.lineups && currentReport.lineups.length) {
      players = currentReport.lineups;
      homePlayers = players.filter(
        (player) =>
          player.team === currentReport.home.scId || player.team === currentReport.home.id,
      );
      awayPlayers = players.filter(
        (player) =>
          player.team === currentReport.away.scId || player.team === currentReport.away.id,
      );
    }
    const isRandomLineups =
      currentReport.isRandomLineups === true || currentReport.isRandomLineups === false
        ? currentReport.isRandomLineups
        : !players
            .filter((player) => !['GK1', 'GK2', 'GK3'].includes(player.position))
            .every((player) => player.line && player.line !== 'goalkeepers');

    if (!isRandomLineups) {
      return {
        home: this.extractGameLineupReport(homePlayers),
        away: this.extractGameLineupReport(awayPlayers),
      };
    }
    return {
      home: {},
      away: {},
    };
  }

  private extractGameLineupReport(players) {
    // goalies
    const gk1 = players.find((p) => p.position === 'GK1');
    const gk2 = players.find((p) => p.position === 'GK2');
    const gk3 = players.find((p) => p.position === 'GK3');

    // skater
    const lineupPlayers = {};
    ['1', '2', '3', '4', '5'].forEach((line) => {
      lineupPlayers[line] = {};
      ['RD', 'LD', 'RW', 'LW', 'C'].forEach((position) => {
        const reportPlayer = players.find(
          (p) =>
            p.position && p.position.toLowerCase() === position.toLowerCase() && p.line === line,
        );
        lineupPlayers[line][position] = reportPlayer ? reportPlayer.scId : undefined;
      });
    });
    return {
      goalies: {
        GK1: gk1 ? gk1.scId : undefined,
        GK2: gk2 ? gk2.scId : undefined,
        GK3: gk3 ? gk3.scId : undefined,
      },
      lines: lineupPlayers,
    };
  }
}

function ScoutingReportResource($resource, SCConfiguration) {
  return $resource(
    SCConfiguration.getEndpoint() + '/api/scouting/reports/:id/:endpoint',
    { id: '@_id' },
    {
      query: {
        url: SCConfiguration.getEndpoint() + '/api/scouting/filters/:type',
        method: 'POST',
        isArray: true,
      },
      getFiltersStatistics: {
        url: SCConfiguration.getEndpoint() + '/api/scouting/filters/:type/statistics',
        method: 'POST',
        isArray: false,
      },
      update: { method: 'PUT' },
      getTags: {
        isArray: true,
        params: { endpoint: 'tags' },
      },
      getAttachedTaggingLists: {
        isArray: true,
        params: { endpoint: 'attached-tagging-lists' },
      },
      removeReportTag: {
        isArray: false,
        method: 'DELETE',
        params: { endpoint: 'report-tag' },
      },
      removePlayerTag: {
        isArray: false,
        method: 'DELETE',
        params: { endpoint: 'player-tag' },
      },
    },
  );
}

angular
  .module('app.scouting')
  .service('ScoutingService', ScoutingService)
  .factory('ScoutingReportResource', ScoutingReportResource)
  .constant('LEAGUE_IMPORTANT_PROPERTIES', ['_id', 'name'])
  .constant('PLAYER_IMPORTANT_PROPERTIES', [
    '_id',
    'firstName',
    'lastName',
    'imageUrl',
    'playerPosition',
    'dateOfBirth',
    'height',
    'weight',
    'shoots',
    'catches',
    'contract',
    'country.iso3166_3',
    'country.name',
    'country._id',
    'secondaryCountry.iso3166_3',
    'secondaryCountry.name',
    'secondaryCountry._id',
  ])
  .constant('TEAM_IMPORTANT_PROPERTIES', [
    '_id',
    'name',
    'imageUrl',
    'country.iso3166_3',
    'country.name',
    'country._id',
    'latestTeamStats.league._id',
    'latestTeamStats.league.name',
  ]);
