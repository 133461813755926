var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var ScoutingLineupItemController = (function () {
    function ScoutingLineupItemController(SearchService, PLAYER_IMPORTANT_PROPERTIES) {
        this.PLAYER_IMPORTANT_PROPERTIES = PLAYER_IMPORTANT_PROPERTIES;
        this.$searchService = SearchService;
    }
    ScoutingLineupItemController.prototype.$onInit = function () {
        this.ensureLineExists();
    };
    ScoutingLineupItemController.prototype.$onChanges = function (changes) {
        if (changes.playerId) {
            this.selectedWrapper = this.players.find(function (item) { return item.player._id === _.get(changes, ['playerId', 'currentValue']); });
        }
    };
    ScoutingLineupItemController.prototype.findAvailablePlayer = function (query) {
        return __awaiter(this, void 0, void 0, function () {
            var probableJerseyNumber, textQuery, players;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        probableJerseyNumber = (query.match(/\d+/) || [])[0];
                        textQuery = _.deburr((query.match(/\D/g) || []).join(''))
                            .replace(/\s+/g, ' ')
                            .trim()
                            .toLocaleLowerCase();
                        players = this.players.filter(function (item) { return item.team === (_this.team._id || _this.team.temporaryId); });
                        if (this.exclusive) {
                            players = players.filter(function (item) {
                                return (_this.selectedWrapper && item.player._id === _this.selectedWrapper.player._id) ||
                                    !_this.playerIdsInLineup.has(item.player._id);
                            });
                        }
                        if (this.bucket === 'goalies') {
                            players = players.filter(function (item) { return item.player.playerPosition === 'GOALIE'; });
                        }
                        else {
                            players = players.filter(function (item) { return item.player.playerPosition !== 'GOALIE'; });
                        }
                        players = players.filter(function (item) {
                            var jerseySearchPassed = true;
                            if (probableJerseyNumber) {
                                jerseySearchPassed = (item.jerseyNumber + '').includes(probableJerseyNumber);
                            }
                            if (textQuery && jerseySearchPassed) {
                                return (_.deburr("".concat(item.player.firstName, " ").concat(item.player.lastName))
                                    .toLocaleLowerCase()
                                    .includes(textQuery) ||
                                    _.deburr("".concat(item.player.lastName, " ").concat(item.player.firstName))
                                        .toLocaleLowerCase()
                                        .includes(textQuery));
                            }
                            return jerseySearchPassed;
                        });
                        if (!(!players.length && query.length > 0)) return [3, 2];
                        return [4, this.$searchService.searchForPlayers(query, {
                                goalie: this.position.includes('GK'),
                            })];
                    case 1:
                        players = (_a.sent()).players.map(function (item) {
                            return {
                                player: _.pick(item._source, _this.PLAYER_IMPORTANT_PROPERTIES),
                                selected: false,
                                custom: true,
                            };
                        });
                        _a.label = 2;
                    case 2: return [2, players];
                }
            });
        });
    };
    Object.defineProperty(ScoutingLineupItemController.prototype, "selectedPlayerJerseyNumber", {
        get: function () {
            return this.selectedWrapper ? this.selectedWrapper.jerseyNumber : null;
        },
        set: function (value) {
            this.selectedWrapper.jerseyNumber = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScoutingLineupItemController.prototype, "replacement", {
        get: function () {
            return this.holder.goalies.replacements;
        },
        set: function (value) {
            this.holder.goalies.replacements = value;
        },
        enumerable: false,
        configurable: true
    });
    ScoutingLineupItemController.prototype.setSelectedPlayer = function (wrapper) {
        if (wrapper) {
            if (this.selectedWrapper) {
                this.selectedWrapper.lineup = false;
            }
            if (!this.players.includes(wrapper)) {
                var existingWrapper = this.players.find(function (item) { return item.player._id === wrapper.player._id; });
                if (existingWrapper) {
                    wrapper = existingWrapper;
                }
                else {
                    wrapper.team = this.team._id || this.team.temporaryId;
                    this.players.push(wrapper);
                }
            }
            this.selectedWrapper = wrapper;
            if (!this.direct) {
                this.selectedWrapper.lineup = true;
            }
            _.set(this.holder, this.playerProperyPath, wrapper.player._id);
            this.holder.missing = this.holder.missing.filter(function (item) { return item.player !== wrapper.player._id; });
        }
        else {
            if (this.selectedWrapper) {
                this.selectedWrapper.lineup = false;
            }
            _.set(this.holder, this.playerProperyPath, null);
        }
        this.onUpdate();
    };
    ScoutingLineupItemController.prototype.ensureLineExists = function () {
        if (this.direct) {
            return;
        }
        if (this.line == null) {
            if (!this.holder[this.bucket]) {
                this.holder[this.bucket] = {};
            }
            return;
        }
        if (!this.holder[this.bucket]) {
            this.holder[this.bucket] = [];
        }
        if (!this.holder[this.bucket][this.line]) {
            this.holder[this.bucket][this.line] = {};
        }
    };
    Object.defineProperty(ScoutingLineupItemController.prototype, "playerProperyPath", {
        get: function () {
            if (this.direct) {
                return this.position;
            }
            return this.line == null
                ? "".concat(this.bucket, ".").concat(this.position)
                : "".concat(this.bucket, ".").concat(this.line, ".").concat(this.position);
        },
        enumerable: false,
        configurable: true
    });
    return ScoutingLineupItemController;
}());
angular.module('app.scouting').component('lineupItem', {
    templateUrl: 'scouting/components/general/lineup-item.html',
    controller: ScoutingLineupItemController,
    bindings: {
        line: '<',
        side: '@',
        bucket: '@',
        direct: '@',
        position: '@',
        exclusive: '@',
        team: '<',
        holder: '<',
        players: '<',
        playerIdsInLineup: '<',
        playerId: '<',
        readonly: '<',
        onUpdate: '&',
    },
});
;