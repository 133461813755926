var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var ScoutingFilterCriteriaProvider = (function () {
    function ScoutingFilterCriteriaProvider() {
        var _this = this;
        this.$get = [
            '$filter',
            function ($filter) { return [
                {
                    name: 'creator',
                    field: 'createdBy',
                    element: 'select',
                    displayTransformer: function (item) { return "".concat(item.firstName, " ").concat(item.lastName); },
                    deserialize: function (value) { return _this.deserializePerson(value); },
                    serialize: function (value) { return "".concat(_this.serializeId(value), ":").concat(value.firstName, ":").concat(value.lastName); },
                    features: {
                        querySearch: false,
                        exactSearch: true,
                        rangeSearch: false,
                    },
                },
                {
                    name: 'organization',
                    field: 'organization',
                    element: 'select',
                    displayTransformer: function (item) { return "".concat(item.name); },
                    deserialize: function (value) { return _this.deserializeOrganization(value); },
                    serialize: function (value) { return "".concat(_this.serializeId(value), ":").concat(value.name); },
                    features: {
                        querySearch: false,
                        exactSearch: true,
                        rangeSearch: false,
                    },
                },
                {
                    name: 'creation_date',
                    field: 'createdAt',
                    element: 'date',
                    displayTransformer: function (item) { return $filter('scDate')(item); },
                    deserialize: function (value) { return new Date(value); },
                    serialize: function (value) { return (value ? value.toISOString() : ''); },
                    features: {
                        querySearch: false,
                        exactSearch: false,
                        rangeSearch: true,
                    },
                },
                {
                    name: 'game_date',
                    field: 'game.date',
                    element: 'date',
                    displayTransformer: function (item) { return $filter('scDate')(item); },
                    deserialize: function (value) { return new Date(value); },
                    serialize: function (value) { return (value ? value.toISOString() : ''); },
                    features: {
                        querySearch: false,
                        exactSearch: false,
                        rangeSearch: true,
                    },
                },
                {
                    name: 'report_league',
                    field: 'league',
                    element: 'select',
                    displayTransformer: function (item) { return item.name; },
                    deserialize: function (value) { return _this.deserializeOrganization(value); },
                    serialize: function (value) { return "".concat(_this.serializeId(value), ":").concat(value.name); },
                    features: {
                        querySearch: false,
                        exactSearch: true,
                        rangeSearch: false,
                    },
                },
                {
                    name: 'report_team',
                    field: 'players.team',
                    element: 'select',
                    displayTransformer: function (item) { return item.name; },
                    deserialize: function (value) { return _this.deserializeOrganization(value); },
                    serialize: function (value) { return "".concat(_this.serializeId(value), ":").concat(value.name); },
                    features: {
                        querySearch: false,
                        exactSearch: true,
                        rangeSearch: false,
                    },
                },
                {
                    name: 'player_name',
                    field: 'players.player.lastName',
                    element: 'text',
                    displayTransformer: function (item) { return item; },
                    deserialize: function (value) { return value; },
                    serialize: function (value) { return value; },
                    features: {
                        querySearch: true,
                        exactSearch: false,
                        rangeSearch: false,
                    },
                },
                {
                    name: 'player',
                    field: 'players.player',
                    element: 'select',
                    displayTransformer: function (item) { return "".concat(item.firstName, " ").concat(item.lastName); },
                    deserialize: function (value) { return _this.deserializePerson(value); },
                    serialize: function (value) { return "".concat(_this.serializeId(value), ":").concat(value.firstName, ":").concat(value.lastName); },
                    features: {
                        querySearch: false,
                        exactSearch: true,
                        rangeSearch: false,
                    },
                },
                {
                    name: 'report_player_position',
                    field: 'players.player.playerPosition',
                    element: 'select',
                    displayTransformer: function (item) {
                        return typeof item == 'string' ? $filter('capitalize')(item) : null;
                    },
                    deserialize: function (value) { return value; },
                    serialize: function (value) { return value; },
                    features: {
                        querySearch: false,
                        exactSearch: true,
                        rangeSearch: false,
                    },
                },
                {
                    name: 'tag',
                    field: 'tags',
                    element: 'select',
                    displayTransformer: function (item) { return item; },
                    deserialize: function (value) { return value; },
                    serialize: function (value) { return value; },
                    features: {
                        querySearch: false,
                        exactSearch: true,
                        rangeSearch: false,
                    },
                },
                {
                    name: 'player_nationality',
                    field: 'players.player.country',
                    element: 'select',
                    displayTransformer: function (item) { return item.name; },
                    deserialize: function (value) { return _this.deserializeOrganization(value); },
                    serialize: function (value) { return "".concat(_this.serializeId(value), ":").concat(value.name); },
                    features: {
                        querySearch: false,
                        exactSearch: true,
                        rangeSearch: false,
                    },
                },
                {
                    name: 'player_date_of_birth',
                    field: 'players.player.dateOfBirth',
                    element: 'date',
                    displayTransformer: function (item) { return $filter('scDate')(item); },
                    deserialize: function (value) { return new Date(value); },
                    serialize: function (value) { return (value ? value.toISOString() : ''); },
                    features: {
                        querySearch: false,
                        exactSearch: false,
                        rangeSearch: true,
                    },
                },
                {
                    name: 'player_age',
                    field: 'players.player.age',
                    element: 'number',
                    displayTransformer: function (item) { return item; },
                    deserialize: function (value) { return +value; },
                    serialize: function (value) { return value; },
                    features: {
                        querySearch: false,
                        exactSearch: false,
                        rangeSearch: true,
                    },
                },
            ]; },
        ];
    }
    ScoutingFilterCriteriaProvider.prototype.deserializeOrganization = function (value) {
        var partsWithMongoId = /^([a-z0-9]{24}):(.+)$/.exec(value);
        var partsWithTempId = /^(.+):(.+)$/.exec(value);
        if (partsWithMongoId && partsWithMongoId.length) {
            return {
                _id: partsWithMongoId[1],
                name: partsWithMongoId[2],
            };
        }
        else if (partsWithTempId && partsWithTempId.length) {
            return {
                temporaryId: partsWithTempId[1],
                name: partsWithTempId[2],
            };
        }
        else {
            throw new Error('Cannot parse organization: ' + value);
        }
    };
    ScoutingFilterCriteriaProvider.prototype.deserializePerson = function (value) {
        var partsWithMongoId = /^([a-z0-9]{24}):(.+):(.+)$/.exec(value);
        var partsWithTempId = /^(.+):(.+):(.+)$/.exec(value);
        if (partsWithMongoId && partsWithMongoId.length) {
            return {
                _id: partsWithMongoId[1],
                firstName: partsWithMongoId[2],
                lastName: partsWithMongoId[3],
            };
        }
        else if (partsWithTempId && partsWithTempId.length) {
            return {
                temporaryId: partsWithTempId[1],
                firstName: partsWithTempId[2],
                lastName: partsWithTempId[3],
            };
        }
        else {
            throw new Error('Cannot parse person: ' + value);
        }
    };
    ScoutingFilterCriteriaProvider.prototype.serializeId = function (value) {
        return value._id || value.temporaryId;
    };
    return ScoutingFilterCriteriaProvider;
}());
var ScoutingFilterService = (function () {
    function ScoutingFilterService($http, $filter, SCConfiguration, ScoutingFilterCriteria) {
        this.$http = $http;
        this.endpoint = SCConfiguration.getEndpoint() + '/api/scouting/filters';
        this.criteria = ScoutingFilterCriteria;
    }
    Object.defineProperty(ScoutingFilterService.prototype, "availableCriteria", {
        get: function () {
            return this.criteria;
        },
        enumerable: false,
        configurable: true
    });
    ScoutingFilterService.prototype.queryRange = function (field, filters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$http
                        .post("".concat(this.endpoint, "/range"), { field: field, filters: filters })
                        .then(function (response) { return response.data; })];
            });
        });
    };
    ScoutingFilterService.prototype.queryChoices = function (field, filters, showPublic) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$http
                        .post("".concat(this.endpoint, "/choices?showPublic=").concat(showPublic), { field: field, filters: filters })
                        .then(function (response) { return response.data; })];
            });
        });
    };
    ScoutingFilterService.prototype.serialize = function (filters) {
        var _this = this;
        var transformed = filters.map(function (item) {
            var criterion = _this.criteria.find(function (criterion) { return criterion.name === item.criterion; });
            if (!criterion || item.disabled) {
                return null;
            }
            var value;
            if (item.value.or) {
                value = item.value.or.map(function (value) { return criterion.serialize(value); }).join(',');
            }
            else if (item.value.exact) {
                value = criterion.serialize(item.value.exact);
            }
            else if (item.value.query) {
                value = criterion.serialize(item.value.query);
            }
            else if (item.value.range) {
                value = "(".concat(criterion.serialize(item.value.range.gt), ",").concat(criterion.serialize(item.value.range.lt), ")");
            }
            if (item.negative) {
                value = "not(".concat(value, ")");
            }
            return {
                name: item.criterion,
                value: value,
            };
        });
        return _.chain(transformed)
            .filter(function (item) { return !!item; })
            .groupBy(function (item) { return item.name; })
            .mapValues(function (item) {
            var values = item.map(function (item) { return item.value; }).filter(function (item) { return !!item; });
            return values.length === 1 ? values[0] : values;
        })
            .value();
    };
    ScoutingFilterService.prototype.deserialize = function (serialized) {
        var _this = this;
        var filters = [];
        Object.keys(serialized).forEach(function (criterion) {
            if (!serialized[criterion]) {
                return;
            }
            var instance = _this.criteria.find(function (item) { return item.name === criterion; });
            var values = Array.isArray(serialized[criterion])
                ? serialized[criterion]
                : [serialized[criterion]];
            if (instance && instance.features.exactSearch) {
                try {
                    for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
                        var value = values_1[_i];
                        filters.push(_this.deserializeExactSearchFilter(instance, value));
                    }
                    return;
                }
                catch (error) {
                    console.error('Unable to parse exact search filter:', error);
                }
            }
            if (instance && instance.features.rangeSearch) {
                try {
                    for (var _a = 0, values_2 = values; _a < values_2.length; _a++) {
                        var value = values_2[_a];
                        filters.push(_this.deserializeRangeSearchFilter(instance, value));
                    }
                    return;
                }
                catch (error) {
                    console.error('Unable to parse range filter:', error);
                }
            }
            if (instance && instance.features.querySearch) {
                try {
                    for (var _b = 0, values_3 = values; _b < values_3.length; _b++) {
                        var value = values_3[_b];
                        filters.push(_this.deserializeQuerySearchFilter(instance, value));
                    }
                    return;
                }
                catch (error) {
                    console.error('Unable to parse query filter:', error);
                }
            }
        });
        return filters;
    };
    ScoutingFilterService.prototype.deserializeExactSearchFilter = function (criterion, value) {
        var _a;
        var negative = false;
        if (value.startsWith('not(')) {
            negative = true;
            value = /not\((.+)\)/.exec(value)[1];
        }
        return {
            criterion: criterion.name,
            field: criterion.field,
            negative: negative,
            value: (_a = {},
                _a[criterion.element === 'date' ? 'exact' : 'or'] = criterion.element === 'date'
                    ? criterion.deserialize(value)
                    : value.split(',').map(function (value) { return criterion.deserialize(value); }),
                _a),
        };
    };
    ScoutingFilterService.prototype.deserializeRangeSearchFilter = function (criterion, value) {
        var parts = /^\((.*),(.*)\)$/.exec(value);
        if (parts && parts.length) {
            return {
                criterion: criterion.name,
                field: criterion.field,
                negative: false,
                value: {
                    range: {
                        gt: parts[1] ? criterion.deserialize(parts[1]) : undefined,
                        lt: parts[2] ? criterion.deserialize(parts[2]) : undefined,
                    },
                },
            };
        }
        else {
            return {
                criterion: criterion.name,
                field: criterion.field,
                negative: false,
                value: {
                    exact: criterion.deserialize(value),
                },
            };
        }
    };
    ScoutingFilterService.prototype.deserializeQuerySearchFilter = function (criterion, value) {
        var negative = false;
        if (value.startsWith('not(')) {
            negative = true;
            value = /not\((.+)\)/.exec(value)[1];
        }
        return {
            criterion: criterion.name,
            field: criterion.field,
            negative: negative,
            value: {
                query: value,
            },
        };
    };
    __decorate([
        LastCallAsyncCaching()
    ], ScoutingFilterService.prototype, "queryRange", null);
    __decorate([
        LastCallAsyncCaching()
    ], ScoutingFilterService.prototype, "queryChoices", null);
    return ScoutingFilterService;
}());
angular
    .module('app.scouting')
    .provider('ScoutingFilterCriteria', ScoutingFilterCriteriaProvider)
    .service('ScoutingFilterService', ScoutingFilterService);
;