(function () {
  'use strict';

  class ScoutingReportCheckboxController {
    private params;
    private value;

    constructor(private $scope) {}

    getOptions(player) {
      switch (player.player.playerPosition) {
        case 'GOALIE':
          return this.params.goaliesOptions || this.params.options;

        default:
          return this.params.options;
      }
    }

    isChecked(option: string) {
      return this.value && this.value.includes(option);
    }

    toggle(option: string) {
      this.value = this.value || [];

      const index = this.value.indexOf(option);
      switch (index) {
        case -1:
          this.value.push(option);
          break;

        default:
          this.value.splice(index, 1);
          break;
      }

      this.notifyAboutChanges();
    }

    notifyAboutChanges() {
      this.$scope.$emit('scouting:element:changed');
    }
  }

  angular.module('app.scouting').component('scoutingReportFieldCheckbox', {
    templateUrl: 'scouting/components/field/checkbox.html',
    controller: ScoutingReportCheckboxController,
    bindings: { params: '<', value: '=', editable: '<', selectedPlayer: '<' },
  });
})();
