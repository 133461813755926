const COMPETITION_TYPES = {
  regular: 'REGULAR SEASON',
  post: 'POST-SEASON',
  tournament: 'TOURNAMENT',
  frindly: 'FRIENDLY GAME',
  pre: 'PRE-SEASON',
  custom: 'custom...',
};

class ScoutingGameDetailsController {
  private $templateResource;
  private $searchService;
  private $competitionService;
  private $scoutingService;

  private game;
  private team;

  private availableCompetitionTypes;
  private cachedLeagueTeams;
  private isDisablingTeamSwap: boolean;

  constructor(
    private $uibModal,
    private $q,
    private $scope,
    ScoutingTemplateResource,
    SearchService,
    CompetitionService,
    ScoutingService,
    private TEAM_IMPORTANT_PROPERTIES,
    private LEAGUE_IMPORTANT_PROPERTIES,
  ) {
    this.$templateResource = ScoutingTemplateResource;
    this.$searchService = SearchService;
    this.$competitionService = CompetitionService;
    this.$scoutingService = ScoutingService;
  }

  $onInit() {
    if (this.game && this.game.date) {
      this.game.date = moment(this.game.date).toDate();
    }

    if (this.game && !this.game._id) {
      this.game.home = this.game.home || this.team;
    }

    this.$scope.$watchCollection(
      () => this.game,
      () => this.$scope.$emit('scouting:element:changed'),
      true,
    );

    this.$scope.$on('scouting:reportSetup:populatingTeamRoster', (__, { populatingTeamRoster }) => {
      this.isDisablingTeamSwap = populatingTeamRoster;
    });
  }

  findCompetitionType(searchText) {
    return this.predefinedCompetitionTypes.filter(
      (type) =>
        !searchText || type.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1,
    );
  }

  async findLeague(searchText) {
    const [searchResult, temporaryLeagues] = await this.$q.all([
      this.$searchService.searchForLeagues(searchText),
      this.$scoutingService.findTemporaryLeagues(searchText),
    ]);

    const existingLeagues = searchResult.leagues.map((item) =>
      _.pick(item._source, this.LEAGUE_IMPORTANT_PROPERTIES),
    );
    const foundLeagues = existingLeagues.concat(temporaryLeagues);

    if (temporaryLeagues.some((league) => league.name == searchText)) {
      return foundLeagues;
    } else {
      return foundLeagues.concat([
        {
          name: searchText,
          temporaryId: this.$scoutingService.createTemporaryId(),
        },
      ]);
    }
  }

  resetCachedLeagueTeams() {
    this.cachedLeagueTeams = null;
  }

  async findTeam(searchText) {
    const teams = (await this.$searchService.searchForTeams(searchText)).teams;
    const temporaryTeams = await this.$scoutingService.findTemporaryTeams(searchText);
    const existingTeams = teams.map((item) => _.pick(item._source, this.TEAM_IMPORTANT_PROPERTIES));
    const foundTeams = existingTeams.concat(temporaryTeams);

    if (temporaryTeams.some((team) => team.name == searchText)) {
      return foundTeams;
    } else {
      return foundTeams.concat([
        {
          name: searchText,
          temporaryId: this.$scoutingService.createTemporaryId(),
        },
      ]);
    }
  }

  createNewCompetition(name) {
    this.game.league = { name };
  }

  createNewTeam(type, name) {
    this.game[type] = { name };
  }

  swapHomeAndAwayTeams() {
    const home = this.game.home;

    this.game.home = this.game.away;
    this.game.away = home;
  }

  private findTeamWithinLeague(searchText) {
    if (!this.cachedLeagueTeams) {
      this.cachedLeagueTeams = this.$competitionService.getTeamsListArray(this.game.league._id);
    }

    if (!this.cachedLeagueTeams.$resolved) {
      return this.cachedLeagueTeams.$promise.finally(() => this.findTeamWithinLeague(searchText));
    }

    return this.cachedLeagueTeams
      .filter(this.createTeamComparator(searchText))
      .map((item) => ({ _source: item }));
  }

  private createTeamComparator(query) {
    query = _.deburr(query).trim().toLocaleLowerCase();

    return function (team) {
      const teamName = _.deburr(team.name).trim().toLocaleLowerCase();

      return teamName.indexOf(query) >= 0;
    };
  }

  get predefinedCompetitionTypes() {
    return [
      COMPETITION_TYPES.regular,
      COMPETITION_TYPES.post,
      COMPETITION_TYPES.tournament,
      COMPETITION_TYPES.frindly,
      COMPETITION_TYPES.pre,
    ];
  }

  get customCompetitionType() {
    return COMPETITION_TYPES.custom;
  }
}

angular.module('app.scouting').component('scoutingGameDetails', {
  templateUrl: 'scouting/components/general/game-details.html',
  controller: ScoutingGameDetailsController,
  bindings: {
    game: '=',
    team: '<',
    brief: '@',
    compact: '@',
    editable: '<',
  },
});
