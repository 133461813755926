var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ScoutingReportPersonFieldController = (function () {
    function ScoutingReportPersonFieldController($scope, $q, SearchService, ScoutingService) {
        this.$scope = $scope;
        this.$q = $q;
        this.$searchService = SearchService;
        this.$scoutingService = ScoutingService;
    }
    ScoutingReportPersonFieldController.prototype.notifyAboutChanges = function () {
        var _this = this;
        this.value = this.runtimeValue.filter(function (item) { return !!item; });
        this.value.forEach(function (item) {
            if (!item._id && !item.temporaryId) {
                item.temporaryId = _this.$scoutingService.createTemporaryId();
            }
        });
        this.$scope.$emit('scouting:element:changed');
    };
    ScoutingReportPersonFieldController.prototype.$onChanges = function (changes) {
        var value = _.get(changes, 'value.currentValue', this.value);
        if (changes.selectedPlayer) {
            this.initializeRuntimeValue(value);
        }
    };
    ScoutingReportPersonFieldController.prototype.initializeRuntimeValue = function (value) {
        this.runtimeValue = (value || []).slice();
        if (this.runtimeValue.length === 0) {
            this.runtimeValue.push(null);
        }
    };
    ScoutingReportPersonFieldController.prototype.searchStaffsAndPlayers = function (query) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, searchResult, temporaryPeople, searchList;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.$q.all([
                            this.$searchService.queryQuickSearch(query, true),
                            this.$scoutingService.findTemporaryPeople(query),
                        ])];
                    case 1:
                        _a = _b.sent(), searchResult = _a[0], temporaryPeople = _a[1];
                        searchList = _.sortBy(__spreadArray(__spreadArray([], searchResult.staff, true), searchResult.players, true), function (item) { return item._score; }).map(function (item) {
                            var base = _.pick(item._source, [
                                '_id',
                                'firstName',
                                'lastName',
                                'imageUrl',
                                'dateOfBirth',
                                'temporaryId',
                            ]);
                            var meta = {
                                type: item._type,
                                function: item._type === 'players'
                                    ? _.get(item._source, 'latestPlayerStats.playerPosition')
                                    : _.get(item._source, 'latestStaffStats.staffRole'),
                            };
                            return Object.assign(base, meta);
                        });
                        return [2, searchList
                                .concat(temporaryPeople)
                                .concat([{}])
                                .filter(function (item) {
                                return !_this.runtimeValue.some(function (person) {
                                    return person &&
                                        (person._id === item._id ||
                                            (person.temporaryId && person.temporaryId === item.temporaryId));
                                });
                            })];
                }
            });
        });
    };
    ScoutingReportPersonFieldController.prototype.addOneMorePerson = function () {
        this.runtimeValue.push(null);
    };
    ScoutingReportPersonFieldController.prototype.resetPerson = function (index) {
        this.runtimeValue[index] = null;
        this.notifyAboutChanges();
    };
    ScoutingReportPersonFieldController.prototype.deletePerson = function (index) {
        this.runtimeValue.splice(index, 1);
        this.notifyAboutChanges();
    };
    return ScoutingReportPersonFieldController;
}());
angular.module('app.scouting').component('scoutingReportFieldPerson', {
    templateUrl: 'scouting/components/field/person.html',
    controller: ScoutingReportPersonFieldController,
    bindings: { params: '<', value: '=', editable: '<', selectedPlayer: '<' },
});