class ScoutingLabelsService {
  private $labelsResource;

  constructor(
    private $q,
    ScoutingLabelsResource,
  ) {
    this.$labelsResource = ScoutingLabelsResource;
  }

  applyChanges(availableLabels, changes: LabelPlayersChange[]) {
    const labels = _.groupBy(changes, 'label');
    const promises = [];

    for (const label in labels) {
      const instance = availableLabels.find((item) => item._id === label);
      const removals = labels[label].filter((item) => item.type === 'remove');
      const additions = labels[label].filter((item) => item.type === 'add');

      promises.push(this.applyPlayersChangesToLabel(instance, additions, removals));
    }

    return this.$q.all(promises);
  }

  private async applyPlayersChangesToLabel(
    label: Label,
    additions: LabelPlayersChange[],
    removals: LabelPlayersChange[],
  ) {
    let result;

    if (removals.length) {
      result = await this.$labelsResource.removePlayers(
        { id: label._id },
        { players: removals.map((item) => item.player) },
      ).$promise;
    }

    if (additions.length) {
      result = await this.$labelsResource.addPlayers(
        { id: label._id },
        { players: additions.map((item) => item.player) },
      ).$promise;
    }

    angular.copy(result, label);
  }
}

function ScoutingLabelsResource($resource, SCConfiguration) {
  return $resource(
    SCConfiguration.getEndpoint() + '/api/scouting/labels/:id/:endpoint',
    { id: '@_id' },
    {
      queryPlayers: {
        method: 'GET',
        isArray: true,
        params: { endpoint: 'players' },
      },

      updateDetails: {
        method: 'PUT',
        params: { endpoint: 'details' },
      },

      updateRanks: {
        method: 'PUT',
        params: { endpoint: 'ranks' },
      },
      addPlayers: {
        method: 'PUT',
        params: { endpoint: 'add-players' },
      },
      removePlayers: {
        method: 'PUT',
        params: { endpoint: 'remove-players' },
      },
      toggleFavourite: {
        method: 'PUT',
        params: { endpoint: 'toggle-favourite' },
      },
    },
  );
}

angular
  .module('app.scouting')
  .service('ScoutingLabelsService', ScoutingLabelsService)
  .factory('ScoutingLabelsResource', ScoutingLabelsResource);
