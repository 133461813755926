class ScoutingLabelsController {
  private labelsHolder;
  private playerId;
  private onUpdateTable;

  constructor(
    private ScoutingLabelsResource,
    private $filter,
    private $uibModal,
  ) {}

  async removeLabel(label) {
    const confirmationText = this.$filter('translate')('scouting.Delete_list_confirmation', {
      listName: label.label.name,
    });

    const modalInstance = this.$uibModal.open({
      size: 'md',
      template:
        '<confirmation-modal modal-instance="$ctrl.modalInstance" translated-message="$ctrl.translatedMessage" />',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.translatedMessage = confirmationText;
        },
      ],
      controllerAs: '$ctrl',
    });

    try {
      await modalInstance.result;

      const idx = this.labelsHolder.labels.indexOf(label);
      this.labelsHolder.labels.splice(idx, 1);

      this.ScoutingLabelsResource.removePlayers(
        { id: label._id },
        { players: [{ _id: this.playerId }] },
      ).$promise.then(() => {
        this.onUpdateTable();
      });
    } catch (_e) {
      // Modal was dismissed (user clicked Cancel or X)
      // Do nothing
    }
  }
}

angular.module('app.scouting').component('scoutingLabels', {
  templateUrl: 'scouting/components/general/labels.html',
  controller: ScoutingLabelsController,
  bindings: {
    labelsHolder: '<',
    playerId: '<',
    onUpdateTable: '&',
  },
});
