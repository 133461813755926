(function () {
  'use strict';

  class ScoutingReportFooterController {
    private $scoutingService;

    private report;
    private editable;
    private selectedPlayer;

    constructor(
      private $uibModal,
      ScoutingService,
    ) {
      this.$scoutingService = ScoutingService;
    }

    delete() {
      const modalInstance = this.$uibModal.open({
        size: 'sm',
        template: '<modal-report-delete modal-instance="$ctrl.modalInstance">',
        controllerAs: '$ctrl',
        controller: [
          '$uibModalInstance',
          function ($uibModalInstance) {
            this.modalInstance = $uibModalInstance;
          },
        ],
      });

      modalInstance.result
        .then(() => (this.report.$promise = this.report.$delete()))
        .then(() => this.$state.go('root.scouting.player_reports', null, { location: 'replace' }));
    }

    cancel() {
      this.editable = false;
      this.$scoutingService.triggerChangedReportSave(this.report);
    }
  }

  angular.module('app.scouting').component('scoutingReportFooter', {
    templateUrl: 'scouting/components/report/footer.html',
    controller: ScoutingReportFooterController,
    bindings: { report: '<', editable: '=', selectedPlayer: '=' },
  });
})();
