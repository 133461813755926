var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var DEFAULT_PAGINATION_SIZE = 10;
var UNLIMITED_PAGINATION_SIZE = 999999;
var ScoutingDepartmentPlayersController = (function () {
    function ScoutingDepartmentPlayersController($timeout, ScoutingService, ScoutingLabelsResource, ScoutingLabelsService, Paginator, $uibModal) {
        this.$timeout = $timeout;
        this.ScoutingService = ScoutingService;
        this.$uibModal = $uibModal;
        this.$labelsResource = ScoutingLabelsResource;
        this.$labelsService = ScoutingLabelsService;
        this.$paginator = Paginator;
    }
    ScoutingDepartmentPlayersController.prototype.$onInit = function () {
        var _this = this;
        this.uiTreeConfig = {
            dragStart: function (event) {
                event.elements.placeholder.append('<td colspan="9999">');
            },
            dropped: function (_event) {
                _this.$timeout(function () { return _this.populateRanks(); });
            },
        };
    };
    ScoutingDepartmentPlayersController.prototype.$onChanges = function (changes) {
        var _this = this;
        if (changes.selectedLabel) {
            this.hasRankSorting = !!this.selectedLabel && !!this.selectedLabel.hasRanks;
            this.paginator = this.$paginator.getInstance({
                sortBy: this.hasRankSorting ? 'rank' : 'player.lastName',
                strategy: changes.selectedLabel.currentValue
                    ? 'PaginatorNormalStrategy'
                    : 'PaginatorPreloadStrategy',
                limit: changes.selectedLabel.currentValue
                    ? UNLIMITED_PAGINATION_SIZE
                    : DEFAULT_PAGINATION_SIZE,
                pagingFunction: function (params) {
                    return _this.$labelsResource.queryPlayers(Object.assign({ id: _.get(changes.selectedLabel.currentValue, '_id') }, params)).$promise;
                },
            });
        }
        if (changes.availableLabels) {
            var availableLabels_1 = changes.availableLabels.currentValue;
            if (this.paginator.data && this.paginator.data.length) {
                this.paginator.data = this.paginator.data.map(function (item) {
                    item.labels = (item.labels || []).map(function (label) { return availableLabels_1.find(function (avlb) { return avlb._id === label._id; }) || label; });
                    return item;
                });
            }
        }
    };
    ScoutingDepartmentPlayersController.prototype.populateRanks = function () {
        var list = this.paginator.data;
        if (this.paginator.paginationModel.direction === -1 &&
            this.paginator.paginationModel.sort === 'rank') {
            list = list.slice().reverse();
        }
        this.hasRankSorting = true;
        this.paginator.paginationModel.sort = 'rank';
        var ranks = list.map(function (item, index) {
            item.rank = index;
            return { player: item._id, rank: item.rank };
        });
        this.$labelsResource.updateRanks({ id: this.selectedLabel._id }, { ranks: ranks });
    };
    ScoutingDepartmentPlayersController.prototype.hasNamePredicate = function () {
        return function (selectedLabel) { return !!selectedLabel.label; };
    };
    Object.defineProperty(ScoutingDepartmentPlayersController.prototype, "isAllPlayersSelected", {
        get: function () {
            return this.paginator.data.every(function (item) { return item.$$selected; });
        },
        set: function (value) {
            if (value) {
                this.paginator.data.forEach(function (item) { return (item.$$selected = true); });
            }
            else {
                this.paginator.data.forEach(function (item) { return (item.$$selected = false); });
            }
        },
        enumerable: false,
        configurable: true
    });
    ScoutingDepartmentPlayersController.prototype.applyPlayersChanges = function (changes) {
        var _this = this;
        this.promise = this.$labelsService
            .applyChanges(this.availableLabels, changes)
            .then(function () {
            _this.paginator.reset();
            _this.onChange();
            return _this.paginator.lastPromise;
        })
            .then(function () { return (_this.promise = null); });
    };
    ScoutingDepartmentPlayersController.prototype.updateTable = function () {
        var _this = this;
        this.paginator.reset();
        this.promise = this.paginator.lastPromise.then(function () {
            _this.onChange();
            _this.promise = null;
        });
    };
    ScoutingDepartmentPlayersController.prototype.compareReports = function () {
        var players = this.paginator.data
            .filter(function (item) { return item.$$selected; })
            .map(function (item) {
            return __assign(__assign({}, item.player), { user: item.user });
        });
        this.ScoutingService.compareReports(players, []);
    };
    ScoutingDepartmentPlayersController.prototype.showCommentsBoard = function () {
        this.ScoutingService.showCommentsBoard(this.paginator.data);
    };
    ScoutingDepartmentPlayersController.prototype.createNewReport = function () {
        this.ScoutingService.requestReportCreation();
    };
    ScoutingDepartmentPlayersController.prototype.removePlayer = function (player, labels) {
        return __awaiter(this, void 0, void 0, function () {
            var confirmationText, modalTitle, modalInstance, changes, _e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modalTitle = 'Confirm Removal';
                        if (!this.selectedLabel) {
                            modalTitle = 'WARNING: Complete Removal';
                            confirmationText = "\n        <div class=\"text-danger\">\n          <strong>WARNING: This will completely remove the player from the Scouting Department!</strong>\n        </div>\n        <p>Player \"".concat(player.firstName, " ").concat(player.lastName, "\" will be removed from ALL lists (").concat(labels.length, " lists total).</p>\n        <p>This action cannot be undone. Are you sure you want to proceed?</p>\n      ");
                        }
                        else {
                            confirmationText = "Are you sure you want to remove player \"".concat(player.firstName, " ").concat(player.lastName, "\" from the \"").concat(this.selectedLabel.label.name, "\" list?");
                        }
                        modalInstance = this.$uibModal.open({
                            size: 'md',
                            template: '<confirmation-modal modal-instance="$ctrl.modalInstance" translated-message="$ctrl.translatedMessage" modal-title="$ctrl.modalTitle" />',
                            controller: [
                                '$uibModalInstance',
                                function ($uibModalInstance) {
                                    this.modalInstance = $uibModalInstance;
                                    this.translatedMessage = confirmationText;
                                    this.modalTitle = modalTitle;
                                },
                            ],
                            controllerAs: '$ctrl',
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, modalInstance.result];
                    case 2:
                        _a.sent();
                        this.paginator.data = this.paginator.data.filter(function (item) { return item.player._id !== player._id; });
                        if (!this.selectedLabel) {
                            changes = labels.map(function (label) {
                                return { type: 'remove', player: player, label: label._id };
                            });
                            this.applyPlayersChanges(changes);
                        }
                        else {
                            this.applyPlayersChanges([
                                { type: 'remove', player: player, label: this.selectedLabel._id || null },
                            ]);
                        }
                        return [3, 4];
                    case 3:
                        _e_1 = _a.sent();
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    return ScoutingDepartmentPlayersController;
}());
angular.module('app.scouting').component('scoutingDepartmentPlayers', {
    templateUrl: 'scouting/components/department-players.html',
    controller: ScoutingDepartmentPlayersController,
    bindings: { selectedLabel: '<', availableLabels: '<', onChange: '&', onCreateLabel: '&' },
});