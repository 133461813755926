import { Contacts, PlayerRecord, PlayerService } from '../../player/services/PlayerService';

class ScoutingDepartmentPlayerAgentsController {
  private $playerService: PlayerService;

  private player: PlayerRecord;

  private promise: Promise<Contacts>;
  private contacts: Contacts;

  constructor(PlayerService) {
    this.$playerService = PlayerService;
  }

  $onInit() {
    this.promise = this.$playerService.getContacts(this.player._id);
    this.promise.then((contacts) => (this.contacts = contacts));
  }
}

angular.module('app.scouting').component('scoutingDepartmentPlayerAgents', {
  templateUrl: 'scouting/components/department-player-agents.html',
  controller: ScoutingDepartmentPlayerAgentsController,

  bindings: {
    player: '<',
  },
});
